<app-header></app-header>
<ul class="nav nav-tabs justify-content-center">
  <li class="nav-item">
    <a *ngIf="tabActivo==='venta'" class="nav-link active" aria-current="page" aria-disabled="true">Venta</a>
    <a *ngIf="tabActivo!='venta'" class="nav-link" (click)="clickUsuario('venta')" style="cursor: pointer;">Venta</a>
  </li>
  <li class="nav-item">
    <a *ngIf="tabActivo==='producto'" class="nav-link active" aria-current="page" aria-disabled="true">Productos</a>
    <a *ngIf="tabActivo!='producto'" class="nav-link" (click)="clickUsuario('producto')" style="cursor: pointer;">Productos</a>
  </li>
  <li class="nav-item">
    <a *ngIf="tabActivo==='compras'" class="nav-link active" aria-current="page" aria-disabled="true">Compras</a>
    <a *ngIf="tabActivo!='compras'" class="nav-link" (click)="clickUsuario('compras')" style="cursor: pointer;">Compras</a>
  </li>
  
</ul>
<app-venta *ngIf="tabActivo==='venta'"></app-venta>
<app-lista-productos *ngIf="tabActivo==='producto'"></app-lista-productos>
<app-menu-compras *ngIf="tabActivo==='compras'"></app-menu-compras>
