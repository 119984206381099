<app-header></app-header>
<div class="container">
    <div *ngIf="HighlightRow === -1">
      <div class="card text-center">
        <div *ngIf="isWorking" class="row">
          <div class="col">
            <div *ngIf="isWorking" class="spinner-border" role="status">
              <span class="visually">B&B</span>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col">Consulta Médica</div>
          <div class="col">
            <span class="border">
              <fa-icon [icon]="faUserDoctor"></fa-icon>{{fechaActual | date:'dd-MMM-YYYY'}}
            </span>
          </div>
        </div>
        <table class="table table-primary table-bordered" style="margin: auto;">
          <thead>
            <tr>
              <th rowspan="2" style="width: 20%; text-align: center; vertical-align: middle;">Paciente</th>
              <th rowspan="2" style="width: 10%; text-align: center; vertical-align: middle;">Hora Cita</th>
              <th colspan="11" style="width: 65%; text-align: center">Signos</th>
            </tr>
            <tr>
              <th style="width: 7%; text-align: center;font-size: small; ">Temp</th>
              <th style="width: 7%; text-align: center;font-size: small;">Talla</th>
              <th style="width: 7%; text-align: center;font-size: small;">Peso</th>
              <th style="width: 7%; text-align: center;font-size: small;">Sis</th>
              <th style="width: 7%; text-align: center;font-size: small;">Dias</th>
              <th style="width: 6%; text-align: center;font-size: small;">F.C.</th>
              <th style="width: 6%; text-align: center;font-size: small;">F.R.</th>
              <th style="width: 6%; text-align: center;font-size: small;">SPO2</th>
              <th style="width: 6%; text-align: center;font-size: small;">Gluco</th>
              <th style="width: 5%; text-align: center;font-size: small;">Dolor</th>
              <th style="width: 6%; text-align: center;font-size: small;">IMC</th>
            </tr>
          </thead>
        </table>
        <div style="width: 100%; border: 2px solid rgb(216, 238, 241);overflow: auto;height: 480px;">
          <table class="table table-hover" style="margin: auto;">
            <tbody>
              <tr *ngFor="let cita of citas; let i = index"  
                    [class.selectedMedico]="i == HighlightRow" 
                    (click)="atenderPaciente(i)" >
                <td style="width: 20%;" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.paciente.nombre}} {{cita.paciente.apellidoP}}</td>
                <td style="width: 10%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.horaCita}}</td>
                <td style="width: 7%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].temperatura+'ºC' : ''}}</td>
                <td style="width: 7%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].estatura+'cm' : ''}}</td>
                <td style="width: 7%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].peso+'kg' : ''}}</td>
                <td style="width: 7%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].presionSis : ''}}</td>
                <td style="width: 7%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].presionDias : ''}}</td>
                <td style="width: 6%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].frecuenciaCardiaca : ''}}</td>
                <td style="width: 6%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].frecuenciaRespiratoria : ''}}</td>
                <td style="width: 6%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].spo2 : ''}}</td>
                <td style="width: 6%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].glucotest : ''}}</td>
                <td style="width: 5%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ? cita.signos[cita.signos.length-1].escalaDolor : ''}}</td>
                <td style="width: 6%" [style]="cita.isAtendido?'background-color:darkgrey':'background-color: aquamarine;'">{{cita.signos.length != 0 ?  (cita.signos[cita.signos.length-1].peso/(cita.signos[cita.signos.length-1].estatura*cita.signos[cita.signos.length-1].estatura/10000) | number:'1.0-2') : ''}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <table *ngIf="HighlightRow != -1" class="table">
      <colgroup>
        <col width="25%">
        <col width="25%">
        <col width="25%">
        <col width="25%">
      </colgroup>
      <thead>
        <tr>
          <th style="text-align: center;font-size: small;">Socializar/Escuchar al paciente</th>
          <th style="text-align: center;font-size: small;">Revisar/Oscultar</th>
          <th style="text-align: center;font-size: small;">Diagnosticar</th>
          <th style="text-align: center;font-size: small;">Cerrar</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="height: 5px;">
              <div class="progress-bar bg-success" [style.width]="intervaloE1Porcentaje+'%'"></div>
            </div>
          </td>
          <td>
            <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="height: 5px;">
              <div class="progress-bar bg-success" [style.width]="intervaloE2Porcentaje+'%'"></div>
            </div>
          </td>
          <td>
            <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="height: 5px;">
              <div class="progress-bar bg-success" [style.width]="intervaloE3Porcentaje+'%'"></div>
            </div>
          </td>
          <td>
            <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="height: 5px;">
              <div class="progress-bar bg-success" [style.width]="intervaloE4Porcentaje+'%'"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    
    <div  *ngIf="HighlightRow != -1">
      <table class="table table-success table-bordered">
        <colgroup>
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
          <col width="10%">
        </colgroup>
        <thead>
          <tr>
            <th colspan="9" style="font-size: larger;text-align: center;text-decoration: solid;">{{paciente.nombre}} {{paciente.apellidoP}}</th>
            <th style="text-align: center;color: darkblue;"> <button class="btn btn-outline-primary" (click)="imprimeReceta()"> <fa-icon [icon]="faPrint" style="cursor: pointer;" title="Imprimir Receta" ></fa-icon></button> </th>
          </tr>
          <tr>
            <th style="text-align: center;font-size: small;">Temp</th>
            <th style="text-align: center;font-size: small; ">Talla</th>
            <th style="text-align: center;font-size: small;">Peso</th>
            <th style="text-align: center;font-size: small;">IMC</th>
            <th style="text-align: center;font-size: small;">T/A</th>
            <th style="text-align: center;font-size: small;">FR</th>
            <th style="text-align: center;font-size: small;">FC</th>
            <th style="text-align: center;font-size: small;">SPO2</th>
            <th style="text-align: center;font-size: small;">Glucotest</th>
            <th style="text-align: center;font-size: small;">E. Dolor</th>
          </tr>
        </thead>
      </table>
      <div style="height: 100px; overflow: auto; margin-top: -15px;">
        <table class="table table-hover table-bordered">
          <colgroup>
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
          </colgroup>
          <tbody>
            <ng-container *ngFor="let signo of listaSignos">
            <tr>
              <td style="text-align: center;">{{signo.temperatura}}&deg;C</td>
              <td style="text-align: center;">{{signo.estatura}}cm</td>
              <td style="text-align: center;">{{signo.peso}}Kg</td>
              <td style="text-align: center;">{{signo.peso/(signo.estatura*signo.estatura/10000) | number:'1.0-2'}}</td>
              <td style="text-align: center;">{{signo.presionSis}} - {{cita.signos[0].presionDias}}mmHg</td>
              <td style="text-align: center;">{{signo.frecuenciaRespiratoria}}</td>
              <td style="text-align: center;">{{signo.frecuenciaCardiaca}}ppm</td>
              <td style="text-align: center;">{{signo.spo2}}%</td>
              <td style="text-align: center;">{{signo.glucotest}}</td>
              <td style="text-align: center;">{{signo.escalaDolor}}</td>
            </tr>
            <tr>
              <td colspan="2" style="font-family: 'Courier New', Courier, monospace;font-weight: bold;">Motivo de Consulta:</td>
              <td colspan="8" style="font-family: 'Courier New', Courier, monospace;">{{signo.motivoConsulta}}</td>
            </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col text-start">Receta</div>
        <div class="col text-end">
          <button class="btn btn-sm btn-outline-primary"  (click)="abreBuscaMedicamento()" title="Buscar medicamento">Buscar Medicamento 
            <fa-icon [icon]="faPrescriptionBottleMedical" style="cursor: pointer;"></fa-icon>
          </button>
        </div>
        <div class="col text-end">
          <button class="btn active btn-sm btn-primary" (click)="toggleInterconsulta()" data-bs-toggle="button" aria-pressed="true">Interconsulta</button>
        </div>
      </div>
      <div class="row" style="border: 1px solid rgb(12, 92, 10);height: 120px;overflow: auto;">
        <div class="col">
          <table class="table table-borderless">
            <colgroup>
              <col width="60%">
              <col width="30%">
              <col width="10%">
            </colgroup>
            <tbody>
              <tr *ngFor="let medicamento of receta.medicamentoReceta; let i = index">
                <td>{{(i+1)}}.-{{medicamento.nombre}}</td>
                <td><input type="text" name="prescripcion" class="form-control" #prescipcion="ngModel" [(ngModel)]="medicamento.prescripcion" ></td>
                <td>
                  <button class="btn btn-sm" (click)="deleteMedicamentoLista(i)">
                    <fa-icon [icon]="faDeleteLeft"></fa-icon>
                  </button> 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col" style="text-align: center;">Exploración Física</div>
        <div class="col" style="text-align: center;">Diagnóstico</div>
        <div class="col" style="text-align: center;">Tratatmiento no farmacológico</div>
        <div *ngIf="cita.isInterconsulta" class="col" style="text-align: center;">Interconsulta</div>
      </div>
      <div class="row">
        <div class="col" style="border-right: 1px solid green;">
          <textarea name="exploracionFisica" class="form-control" cols="30" rows="6" #exploracionFisica="ngModel" [(ngModel)]="cita.exploracionFisica"></textarea>
        </div>
        <div class="col" style="border-right: 1px solid green;">
          <textarea name="diagnostico" class="form-control" cols="30" rows="6" #diagnostico="ngModel" [(ngModel)]="cita.diagnostico"></textarea>
        </div>
        <div class="col" style="border-right: 1px solid green;">
          <div class="row">
            <div class="col input-group mb-3">
              <input type="text" name="tratamiento" class="form-control" placeholder="Indicaciones" [(ngModel)]="tratamiento" aria-describedby="button-addon2"/> 
              <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="addTratamiento()">+</button>
            </div>
          </div>
          <div style="height: 100px;overflow: auto;">
            <div *ngFor="let tratamiento of cita.tratamiento; let i=index;" class="row">
              <div class="col-10">
                <label style="font-size: small;font-family: 'Courier New', Courier, monospace;">{{tratamiento}}</label>
              </div>
              <div class="col-2">
                <button class="btn btn-sm" (click)="removeTratamiento(i)">
                  <fa-icon [icon]="faDeleteLeft"></fa-icon>
                </button>
              </div> 
            </div>
          </div>
        </div>
        <div *ngIf="cita.isInterconsulta" class="col">
          <div class="row">
            <div class="col">
              <select name="idMedico" class="form-select border-success" [(ngModel)]="idMedicoInterConsulta" [disabled]="!cita.isInterconsulta">
                <option *ngFor="let medico of medicos" [ngValue]="medico._id">
                  {{ medico.nombre }} {{ medico.apellidoP }} - {{ medico.especialidad }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <textarea name="interconsulta" class="form-control" cols="10" rows="4" #interconsulta="ngModel" [(ngModel)]="cita.motivoInterconsulta" [disabled]="!cita.isInterconsulta"></textarea>
            </div>
          </div>
        </div>
      </div>
      
      <div style="text-align: right;">
        <button *ngIf="!cita.isAtendido" class="btn btn-success" (click)="finalizaConsulta()">Finalizar</button>
        <button class="btn btn-dark" (click)="regresar()">Regresar</button>
      </div>
    </div>
  
    <div *ngIf="isMedicamentoOpen" class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="display: block;padding-left: 0px;">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <table>
              <colgroup>
                <col width="20%">
                <col width="20%">
                <col width="60%">
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    <input type="text" name="nombre" class="form-control" #nombre="ngModel" [(ngModel)]="medicamento.nombre" (keyup)="buscaMedicamentoEnter($event)">
                  </td>
                  <td>
                    <button type="submit" class="btn btn-primary" (click)="buscaMedicamento()">Buscar</button>
                    <button class="btn btn-secondary" (click)="limpiarBusqueda()">Limpiar</button>
                  </td>
                  <td style="text-align: center;">
                    <div *ngIf="isSearchingMedicamento" class="spinner-border" role="status">
                      <span class="visually">B&B</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="listaBusquedaMedicamento.length>0" class="row">
              <div class="col">
                Medicamentos encontrados: {{listaBusquedaMedicamento.length}}
              </div>
            </div>
            <table *ngIf="listaBusquedaMedicamento.length>0" class="table table-bordered">
              <colgroup>
                <col width="30%">
                <col width="25%">
                <col width="25%">
                <col width="20%">
              </colgroup>
              <thead>
                <tr>
                  <th style="text-align: center;font-size: small;">Nombre</th>
                  <th style="text-align: center;font-size: small;">Dosis Adulto</th>
                  <th style="text-align: center;font-size: small;">Dosis Niño</th>
                  <th style="text-align: center;font-size: small;">Presentación</th>
                </tr>
              </thead>
            </table>
            <div style="height: 300px;overflow: auto;margin-top: -16px;">
              <table class="table table-bordered table-hover">
                <colgroup>
                  <col width="30%">
                  <col width="25%">
                  <col width="25%">
                  <col width="20%">
                </colgroup>
                <tbody>
                  <tr *ngFor="let medicamento of listaBusquedaMedicamento; let i = index" [class.selected]="i == HighlightMedicamento" (click)="highlightMedicamento(i)" title="{{medicamento.familia}}">
                    <td style="font-size: small;font-family: 'Times New Roman', Times, serif;">{{i+1}}.-&nbsp;{{medicamento.nombre}}</td>
                    <td style="font-size: small;font-family: 'Times New Roman', Times, serif;">{{medicamento.dosisAdulto}}</td>
                    <td style="font-size: small;font-family: 'Times New Roman', Times, serif;">{{medicamento.dosisNino}}</td>
                    <td style="font-size: small;font-family: 'Times New Roman', Times, serif;">{{medicamento.presentaciones}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col">
              <label for="">Agregar medicamento: </label>
            </div>
            <div class="col input-group mb-3">
              <input type="text" class="form-control" name="medicamentoAicional" id="button-addon3" [(ngModel)]="medicamentoAdicional">
              <button class="btn btn-outline-secondary" type="button" id="button-addon3" (click)="addMedicamento()">+</button>
            </div>
            <div class="col text-end">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="cierraModal()">Salir</button>
              <button type="button" class="btn btn-primary" [disabled]="HighlightMedicamento===-1" (click)="seleccionaMedicamento()">Seleccionar</button>
            </div>
          </div>
        </div>
      </div>
    </div>    
</div>