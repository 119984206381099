
<div *ngIf="isLoadingPreguntas" class="container position-absolute top-50 start-50">
  <div class="spinner-border" role="status">
    <fa-icon [icon]="faSpinner"></fa-icon>
  </div>
</div>
<div *ngIf="!isFillingAntecedentes" class="container">
  <div class="row mb-3">
    <div class="col"></div>
  </div>
  <div class="row mb-3">
    <div class="col">  
      <input type="text" class="form-control border-success" [(ngModel)]="nombrePaciente" name="nombrePaciente" placeholder="Nombre" (keyup)="buscarPacienteNombre($event)">        
    </div>
    <div class="col">
      <input type="text" class="form-control border-success" [(ngModel)]="apellidoPaterno" name="apellidoPaterno" placeholder="Apellido Paterno" (keyup)="buscaPacienteApellido($event)" >
    </div>
    <div class="col">
      <button type="button" class="btn btn-primary">Buscar</button>
    </div>
  </div>
  <div *ngIf="listaPacientes.length > 0" class="row">
    <div class="col">
      <table class="table table-dark table-bordered">
        <colgroup>
          <col width="20%"/>
          <col width="20%"/>
          <col width="20%"/>
          <col width="20%"/>
          <col width="20%"/>
        </colgroup>
        <thead>
          <tr>
            <th style="text-align: center;">Nombre</th>
            <th style="text-align: center;">Apellido Paterno</th>
            <th style="text-align: center;">Apellido Materno</th>
            <th style="text-align: center;">Fecha Nacimiento</th>
            <th style="text-align: center;">Teléfono</th>
          </tr>
        </thead>
      </table>
      <div *ngIf="listaPacientes.length > 0" style="margin-top: -17px;overflow: auto;height: 100px;">
        <table class="table table-bordered table-hover">
          <colgroup>
            <col width="20%"/>
            <col width="20%"/>
            <col width="20%"/>
            <col width="20%"/>
            <col width="20%"/>
          </colgroup>
          <tbody>
            <tr *ngFor="let paciente of listaPacientes; let i = index;" (click)="selectPacienteNombre(i)">
              <td style="text-align: center;">{{paciente.nombre}}</td>
              <td style="text-align: center;">{{paciente.apellidoP}}</td>
              <td style="text-align: center;">{{paciente.apellidoM}}</td>
              <td style="text-align: center;">{{paciente.fechaNacimiento | date:'dd-MMM-YYYY'}}</td>
              <td style="text-align: center;">{{paciente.telefono}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
  <div *ngIf="isFillingAntecedentes &&  !isLoadingPreguntas" class="container" >
    <div class="row mb-3">
      <div class="col">
        <label for="" style="font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;font-size: large;">Hola <strong>{{paciente.nombre}} {{paciente.apellidoP}}</strong>, ayúdanos a responder las siguientes preguntas, con el fin de conocer tus antecedetes y ofrecerte un diágnoticos más específico y recomendaciones de salud personalizadas</label>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <div class="progress" role="progressbar" aria-label="Warning example" aria-valuenow='50' aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar text-bg-warning" [style]="'width:'+avancePorcent+'%'">{{currentIndex+1}} de {{lastIndex}}</div>
        </div>
      </div>
    </div>
    <div class="row mb-3" style="border: 1px solid black;height: 350px;background-color: rgb(226, 251, 204);">
      <div class="col">
        <label for="" style="font-size: xx-large; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">{{currentPreguntaAF.pregunta}}</label>
      </div>
      <div class="col">
        <div *ngFor="let respuesta of currentPreguntaAF.respuestas; let i=index" class="form-check form-switch" >
          <input *ngIf="!currentPreguntaAF.opcional" class="form-check-input " type="radio" name="pregunta{{currentPreguntaAF.orden}}" value="{{respuesta.valor}}"  [(ngModel)]="valueRespuesta" id="flexCheckDefault{{i}}" />
          <input *ngIf="currentPreguntaAF.opcional" class="form-check-input" type="checkbox" [checked]="currentPreguntaAF.listaChecked[i]" (change)="currentPreguntaAF.listaChecked[i] = !currentPreguntaAF.listaChecked[i]" name="pregunta{{currentPreguntaAF.orden}}" value="{{respuesta.valor}}"  id="flexCheckDefault" />
          <label class="form-check-label" for="flexCheckDefault" [style]="respuesta.valor===valueRespuesta ? 'color: rgb(70, 159, 25);font-size:larger;': 'color: rgb(89, 88, 118)'  ">  {{respuesta.respuesta}} </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <span *ngIf="currentIndex!=0" (click)="anterior()" style="cursor: pointer;"><fa-icon [icon]="faArrowLeft"></fa-icon>Anterior
        </span>
      </div>
      <div class="col-4">
        <button *ngIf="currentIndex===lastIndex-1 && valueRespuesta!=''" class="btn btn-info" (click)="enviarRespuestas()">Enviar</button>
      </div>
      <div class="col-4" style="text-align: right;">
        <span *ngIf="currentIndex!=lastIndex-1" (click)="siguiente()" style="cursor: pointer;">Siguiente
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </span>
      </div>
    </div>
  </div>

