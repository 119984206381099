<form class="container" style="margin-top: 10px;">
  <div class="row mb-2 g-2">
    <div class="col-sm-2 text-end">Nombre</div>
    <div class="col-sm-2">
      <input type="text" name="nombre" [(ngModel)]="proveedor.nombre" class="form-control form-control-sm border-info">
    </div>
  </div>
  <div class="row mb-2 g-2">
    <div class="col-sm-2 text-end">Dirección</div>
    <div class="col-sm-2">
      <input type="text" name="direccion" [(ngModel)]="proveedor.direccion" class="form-control form-control-sm border-info">
    </div>
  </div>
  <div class="row mb-2 g-2">
    <div class="col-sm-2 text-end">Código Postal</div>
    <div class="col-sm-2">
      <input type="text" name="codigopostal" [(ngModel)]="proveedor.codigoPostal" class="form-control form-control-sm border-info">
    </div>
  </div>
  <div class="row mb-2 g-2">
    <div class="col-sm-2 text-end">RFC</div>
    <div class="col-sm-2">
      <input type="text" name="rfc" [(ngModel)]="proveedor.rfc" class="form-control form-control-sm border-info">
    </div>
  </div>
  <div class="row mb-2 g-2">
    <div class="col-sm-2 text-end">Correo Electrónico</div>
    <div class="col-sm-2">
      <input type="text" name="email" [(ngModel)]="proveedor.email" class="form-control form-control-sm border-info">
    </div>
  </div>
  <div class="row mb-2 g-2">
    <div class="col-sm-2 text-end">Teléfono</div>
    <div class="col-sm-2">
      <input type="text" name="telefono" [(ngModel)]="proveedor.telefono" class="form-control form-control-sm border-info">
    </div>
  </div>
  <div class="row mb-2 g-2">
    <div class="col-sm-2 text-end">Contacto</div>
    <div class="col-sm-2">
      <input type="text" name="contacto" [(ngModel)]="proveedor.personaContacto" class="form-control form-control-sm border-info">
    </div>
  </div>
  <div class="row mb-2 g-2">
    <div class="col-sm-2">
      Activo
    </div>
    <div class="col-sm-2">
      <input type="checkbox" name="activo" clas="form-control-sm" [(ngModel)]="proveedor.isActivo">
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 text-end">
      <button class="btn btn-outline-info" (click)="altaEditaProveedor()" [disabled]="proveedor.nombre===''||proveedor.direccion===''||proveedor.telefono===''">
        {{action}} Proveedor
      </button>
    </div>
    <div class="col-sm-6 text-start">
      <button class="btn btn-outline-dark" (click)="emitEvent(false)">
        Cancelar
      </button>
    </div>
  </div>
</form>
