<div *ngIf="!isAddProduct" class="container">
  <nav class="navbar bg-body-tertiary">
    <div class="container-fluid">
      <form class="d-flex" role="search">
        <input class="form-control me-2" type="search" name="codigoBarras" [(ngModel)]="codigoBarras" placeholder="Código de barras" aria-label="Search">
        <button class="btn btn-outline-info" type="button" (click)="busquedaCodBarras()">
          <span role="status">Búsqueda</span>
          <span *ngIf="isLoadingProd" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        </button>
        <button type="button" class="btn btn-outline-info" (click)="getListaProductos(0)">Limpiar</button>
      </form>
      <button class="btn btn-outline-primary" (click)="addProduct()">Agregar Producto <fa-icon [icon]="faPlusCircle"></fa-icon> </button>
    </div>
  </nav>
  <table class="table table-info">
    <colgroup>
      <col width="15%">
      <col width="30%">
      <col width="20%">
      <col width="20%">
      <col width="15%">
    </colgroup>
    <thead>
      <tr>
        <th class="producto-head">Código</th>
        <th class="producto-head">Descripción</th>
        <th class="producto-head">Departamento</th>
        <th class="producto-head">Precio Venta</th>
        <th class="producto-head">Caducidad</th>
      </tr>
    </thead>
  </table>
  <div style="margin-top: -16px;height: 400px;overflow: auto;border: 1px solid black;">
    <table class="table table-hover table-striped table-bordered">
      <colgroup>
        <col width="15%">
        <col width="30%">
        <col width="20%">
        <col width="20%">
        <col width="15%">
      </colgroup>
      <tbody>
        <tr *ngFor="let producto of productos; let i = index;" (click)="selectRow(i)" (dblclick)="editaProducto()" [class.selected]="i==HighlightRow" style="cursor: pointer;">
          <td class="producto">{{producto.codigoBarras}}</td>
          <td class="producto" title="{{producto.descripcion}}">{{producto.descripcion | wordLength:30}}</td>
          <td class="producto">{{producto.departamento}}</td>
          <td class="producto" style="text-align: center;">{{producto.precioVenta | currency}}</td>
          <td class="producto" style="text-align: center;">{{producto.caducidad | date}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <nav aria-label="...">
    <ul class="pagination justify-content-center">
      <li [className]="anteriorClass">
        <button class="page-link" (click)="prev()">Ant</button>
      </li>
      <li *ngFor="let numero of numeros" class="page-item">
        <button [className]="pageClasses[numero]" (click)="gotoPage(numero)">{{(numero+1)}}</button>
      </li>
      <li [className]="siguienteClass">
        <button class="page-link" (click)="next()">Sig</button>
      </li>
    </ul>
  </nav>
</div>
<app-producto *ngIf="isAddProduct" [producto]="producto" [action]="action" (respuesta)="cancelProductoAltaUpdt($event)" ></app-producto>
