<div class="container">
  <div *ngIf="!isConfirm" class="card">
    <div class="card-header text-center">
      <h3>Administración de Usuarios</h3>
    </div>
    
    <div class="card-body">
      <div class="row">
        <div class="col card">
          <form #altaUserForm="ngForm">
          <h4 class="text-center">{{titulo}} Usuario</h4>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </span>
            <input type="text" name="email" class="form-control" placeholder="Correo Electrónico" aria-label="Username"  aria-describedby="basic-addon1"
              [(ngModel)]="usuario.email" required />
            <span class="input-group-text">
              <fa-icon [icon]="statusEmail"></fa-icon>
            </span>
            <input type="text" name="repeatEmail"
              class="form-control" placeholder="Repetir Correo Electrónico"
              aria-label="Username" aria-describedby="basic-addon1" [(ngModel)]="usuario.repeatEmail" (keyup)="validarEmail()" required/>
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">
              <fa-icon [icon]="faLock"></fa-icon>
            </span>
            <input type="password" name="password" class="form-control" [(ngModel)]="usuario.password" (keyup)="validarPwd()" placeholder="Contraseña"/>
            <span class="input-group-text">
              <fa-icon [icon]="statusPwd"></fa-icon>
            </span>
            <input type="password" name="repeatPassword" class="form-control" [(ngModel)]="usuario.repeatPassword" (keyup)="validarPwd()" placeholder="Repetir Contraseña"  required/>
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">
              <fa-icon [icon]="faUser"></fa-icon>
            </span>
            <input
              type="text"
              name="nombre"
              class="form-control"
              [(ngModel)]="usuario.nombre"
              placeholder="Nombre"
              required
            />
            <input
              type="text"
              name="apellidop"
              class="form-control"
              [(ngModel)]="usuario.apellidoP"
              placeholder="Apellido Paterno"
              required
            />
            <input
              type="text"
              name="apellidom"
              class="form-control"
              [(ngModel)]="usuario.apellidoM"
              placeholder="Apellido Materno"
              required
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text"> Rol </span>
            <select name="idPerfil" [(ngModel)]="usuario.perfil" class="form-select" (change)="perfilChange($event)">
              <option *ngFor="let perf of perfiles" [ngValue]="perf.valor">{{perf.nombre}}</option>
            </select>
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">
              Fecha de Nacimiento
            </span>
            <input type="date" name="dob" class="form-control" [(ngModel)]="usuario.dob" >
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">
              RFC
            </span>
            <input type="text" name="rfc" class="form-control" [(ngModel)]="usuario.rfc" >
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">
              <fa-icon [icon]="faVenusMars"></fa-icon>
            </span>
            <div class="row">
              <div class="col-4">
                M<input type="radio" class="form-check-input" name="sexo" value="M" [checked]="usuario.sexo==='M'" (change)="selectSex('M')"/>
              </div>
              <div class="col-4">
                F<input type="radio" class="form-check-input" name="sexo" value="F" [checked]="usuario.sexo==='F'" (change)="selectSex('F')"/>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">
              <fa-icon [icon]="faPhone"></fa-icon>
            </span>
            <input type="tel" class="form-control" name="telefono" [(ngModel)]="usuario.telefono"  placeholder="Teléfono"/>
          </div>
          <div *ngIf="isMedico()" class="input-group mb-3">
            <span class="input-group-text">
              <fa-icon [icon]="faUserDoctor"></fa-icon>
            </span>
            <select name="idEspecialidad" [(ngModel)]="usuario.especialidad" class="form-select" >
              <option *ngFor="let espec of especialidades" value="{{espec._id}}">{{espec.descripcion}}</option>
            </select>
            <span class="input-group-text">
              <fa-icon [icon]="faScroll"></fa-icon>
            </span>
            <input type="text" class="form-control" name="cedula" placeholder="Cédula" [(ngModel)]="usuario.cedula" />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">
              <fa-icon [icon]="faLocation"></fa-icon>
            </span>
            <select name="idSucursal" [(ngModel)]="usuario.sucursal" class="form-select" >
              <option *ngFor="let suc of sucursales" [ngValue]="suc._id">{{suc.identificador}} - {{suc.codigoPostal}}</option>
            </select>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <button class="btn btn-outline-primary" (click)="signUp()" [disabled]="altaUserForm.invalid" >{{btnAccion}}</button>
              <button class="btn btn-outline-dark" (click)="limpiar()">Limpiar</button>
            </div>
          </div>
        </form>
        </div>
      
        <div class="col">
          <div class="card">
            <h4 style="text-align: center;" class="h2">Lista de Usuarios</h4>
            <table *ngIf="isWorking">
              <colgroup>
                <col width="100%">
              </colgroup>
              <tr>
                <td style="text-align: center;">
                  <div class="spinner-border" role="status">
                    <span class="visually">BB</span>
                  </div>
                </td>
              </tr>
            </table>
            
            <table class="table table-bordered">
              <colgroup>
                <col width="24%">
                <col width="29%">
                <col width="25%">
                <col width="22%">
              </colgroup>
              <thead>
                <tr>
                  <th style="text-align: center;font-size: small;">Nombre</th>
                  <th style="text-align: center;font-size: small;">Apellido</th>
                  <th style="text-align: center;font-size: small;">Perfil</th>
                  <th style="text-align: center;font-size: small;">Acc</th>
                </tr>
              </thead>
            </table>
            <div style="width:100%; height:250px; overflow:auto; margin-top:-17px">
              <table class="table table-bordered table-hover" style="width: 100%;">
                <colgroup>
                  <col width="24%">
                  <col width="29%">
                  <col width="25%">
                  <col width="22%">
                </colgroup>
                <tbody>
                  <tr *ngFor="let usuario of usuarios; let i = index" (click)="selectRow(i)">
                    <td style="font-size: small;">{{usuario.nombre}}</td>
                    <td style="font-size: small;">{{usuario.apellidoP}} {{usuario.apellidoM}}</td>
                    <td style="font-size: small;">{{usuario.perfil}}</td>
                    <td style="text-align: center;font-size: small;">
                      <span class="icon is-small is-left">
                        <button class="btn btn-outline-info" *ngIf="!usuario.isAdmin"  title="Editar Usuario" (click)="editarUsuario(i)">
                          <fa-icon [icon]="faPencil"></fa-icon>
                        </button>
                        <fa-icon *ngIf="usuario.isAdmin" [icon]="faPencil" title="El usuario Administrador no se puede editar"></fa-icon>
                      </span> &nbsp;
                      <span class="icon is-small is-left">
                        <button class="btn btn-primary" *ngIf="!usuario.isAdmin && usuario.isDisabled" (click)="borrarUsuario(i)" title="Habilitar Usuario"><fa-icon [icon]="faUserSlash"></fa-icon></button>
                        <button class="btn btn-primary" *ngIf="!usuario.isAdmin && !usuario.isDisabled" (click)="borrarUsuario(i)" title="Deshabilitar Usuario"><fa-icon [icon]="faUser"></fa-icon></button>
                        <fa-icon *ngIf="usuario.isAdmin" [icon]="faUserSlash" title="El usuario Administrador no se puede deshabilitar"></fa-icon>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isConfirm" class="row g-3">
    <div class="card-header text-center">
      <h1>Confirmar Identidad</h1>
      <div *ngIf="isWorkingIdentidad" class="spinner-border" role="status">
        <fa-icon [icon]="faSpinner"></fa-icon>
      </div>
    </div>
    <div class="col-auto">
      <label for="staticEmail2" class="visually-hidden">Email</label>
      <input type="text" readonly class="form-control-plaintext" id="staticEmail2" value="{{ usuario.email }}"/>
    </div>
    <div class="col-auto">
      <input type="text" class="form-control" name="codigo" [(ngModel)]="usuario.code" placeholder="Código de Verificación"/>
    </div>
    <div class="col-auto">
      <button type="submit" class="btn btn-primary mb-3" (click)="confirmSignUp()">
        Confirmar Indentidad
      </button>
    </div>
  </div>
</div>
