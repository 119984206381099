<div class="container text-center">
  <div class="row">
    <div *ngIf="!isTomandoSignos" class="col">
      <div class="card ">
        <div class="row">
          <div class="col">
            <h4 class="title">Enfermeria -  Toma de Signos</h4>
            <div *ngIf="isWorking" class="spinner-border" role="status">
              <span class="visually">B&B</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span class="icon-text">
              <span class="icon">
                <fa-icon [icon]="faUserNurse"></fa-icon>
              </span>
              <span class="label">{{fechaActual | date:'dd-MMM-YYYY'}}</span>
            </span>
          </div>
        </div>
        <table class="table table-bordered" style="margin: auto;">
          <thead>
            <tr>
              <th style="width: 21%; text-align: center">Paciente</th>
              <th style="width: 7%; text-align: center">Temp</th>
              <th style="width: 8%; text-align: center">Talla</th>
              <th style="width: 8%; text-align: center">Peso</th>
              <th style="width: 8%; text-align: center">Sis</th>
              <th style="width: 8%; text-align: center">Dias</th>
              <th style="width: 8%; text-align: center">F.C.</th>
              <th style="width: 8%; text-align: center">F.R.</th>
              <th style="width: 8%; text-align: center">SPO2</th>
              <th style="width: 8%; text-align: center">Gluco</th>
              <th style="width: 8%; text-align: center">IMC</th>
            </tr>
          </thead>
        </table>
        <div style="text-align: center; width: 100%; height: 450px; border: 2px solid rgb(216, 238, 241);overflow: auto;">
          <table class="table table-bordered table-hover" style="margin: auto">
            <tbody>
              <ng-container *ngFor="let cita of citas; let i = index" >
                <tr (dblclick)="updateSignos(i,0)">
                  <td style="width: 21%" [attr.rowspan]="cita.signos.length===0 ? 1 : cita.signos.length">{{cita.paciente.nombre}} {{cita.paciente.apellidoP}}&nbsp;  <div (click)="nuevaTomaPaciente(i)" style="color: blue;cursor: pointer;"> <fa-icon [icon]="faPlus"></fa-icon> </div></td>
                  <td style="width: 7%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].temperatura+'ºC' : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].estatura+'m' : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].peso+'kg' : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].presionSis : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].presionDias : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].frecuenciaCardiaca : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].frecuenciaRespiratoria : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].spo2 : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? cita.signos[0].glucotest : ''}}</td>
                  <td style="width: 8%;font-size: small;">{{cita.signos[0] != undefined ? (cita.signos[0].peso/(cita.signos[0].estatura*cita.signos[0].estatura/10000) | number:'1.0-2'): ''}}</td>
                </tr>
                <tr *ngFor="let signo of cita.signos | slice:1; let j = index;" (dblclick)="updateSignos(i,j+1)">
                  <td style="width: 7%;font-size: small;">{{signo.temperatura+'ºC'}}</td>
                  <td style="width: 8%;font-size: small;">{{signo.estatura+'cm'}}</td>
                  <td style="width: 8%;font-size: small;">{{signo.peso+'kg'}}</td>
                  <td style="width: 8%;font-size: small;">{{signo.presionSis }}</td>
                  <td style="width: 8%;font-size: small;">{{signo.presionDias}}</td>
                  <td style="width: 8%;font-size: small;">{{signo.frecuenciaCardiaca}}</td>
                  <td style="width: 8%;font-size: small;">{{signo.frecuenciaRespiratoria}}</td>
                  <td style="width: 8%;font-size: small;">{{signo.spo2}}</td>
                  <td style="width: 8%;font-size: small;">{{signo.glucotest}}</td>
                  <td style="width: 8%;font-size: small;">{{ (signo.peso/(signo.estatura*signo.estatura/10000) | number:'1.0-2')}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="isTomandoSignos" class="col">
      <div class="card">
        <div style="border: 1px solid rgba(229, 243, 218, 0.753);">
          <div class="row">
            <div class="col">
              <h5>Medico:</h5>
            </div>
            <div class="col">
              <p class="label">{{medico.nombre}} {{medico.apellidoP}}</p>
            </div>
            <div class="col">
              <p class="label">{{medico.especialidad}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div style="border: 1px solid rgba(229, 243, 218, 0.753);">
          <div class="row">
            <div class="col">
              <h5 class="title ">Paciente:</h5>
            </div>
            <div class="col">
              <p class="label ">{{paciente.nombre}} </p>
            </div>
            <div class="col">
              <p class="label text-center">{{paciente.apellidoP}}</p>
            </div>
            <div class="col">
              <p class="label text-center">{{paciente.apellidoM}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="border: 1px solid rgba(229, 243, 218, 0.753);">
        <h4 class="title text-center">Signos Vitales:</h4>
        <form #userForm="ngForm">
          <div class="row">
            <div class="col">
                <div class="mb-3">
                  <label for="" class="form-label">Temperatura (ºC)</label>
                  <input type="number" [className]="contorno[0]" name="temperatura" #temperatura="ngModel" [(ngModel)]="signos.temperatura" placeholder="Temperatura" (focus)="selectAllContent($event)" (blur)="validaInput(signos.temperatura,0)" required>
                </div>
            </div>
            <div class="col">
              <div class="mb-3">
                  <label for="" class="form-label">Talla (cm)</label>
                  <input type="number" [className]="contorno[1]" name="estatura" #estatura="ngModel" [(ngModel)]="signos.estatura" placeholder="Estatura" (focus)="selectAllContent($event)" (blur)="validaInput(signos.estatura,1)" required>
              </div>
            </div>
            <div class="col">
              
                <label for="" class="form-label">Peso (Kg)</label>
                <input type="number" [className]="contorno[2]" name="peso" #peso="ngModel" [(ngModel)]="signos.peso" placeholder="Peso" (focus)="selectAllContent($event)" (blur)="validaInput(signos.peso,2)" required>
              
            </div>
            <div class="col">
              <label for="" class="form-label">SPO2 (%)</label>
              <input type="number" [className]="contorno[5]" name="spo2" #spo2="ngModel" [(ngModel)]="signos.spo2" (focus)="selectAllContent($event)" (blur)="validaInput(signos.spo2, 5)" required>
            </div>
            <div class="col">
              <label for="" class="form-label">Glucotest</label>
              <input type="number" class="form-control border-success" name="glucotest" #glucotest="ngModel" [(ngModel)]="signos.glucotest" (focus)="selectAllContent($event)" (blur)="validaInput(signos.glucotest,6)" required>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="" class="form-label">P. Sistólica</label>
                <input type="number" class="form-control border-success" name="presionCis" #presionCis="ngModel" [(ngModel)]="signos.presionSis" placeholder="Presión Sistólica" (focus)="selectAllContent($event)" (blur)="validaInput(signos.presionSis,3)" required>
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label for="" class="form-label">P. Diastólica</label>
                <input type="number" class="form-control border-success" name="presionDias" #presionDias="ngModel" [(ngModel)]="signos.presionDias" placeholder="Presión Diastólica" (focus)="selectAllContent($event)" (blur)="validaInput(signos.presionDias,4)" required>
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label for="" class="form-label">F. Cardiaca</label>
                <input type="number" [className]="contorno[3]" name="feccard" #frecuenciaCardiaca="ngModel" [(ngModel)]="signos.frecuenciaCardiaca" (focus)="selectAllContent($event)" (blur)="validaInput(signos.frecuenciaCardiaca,3)" required>
              </div>
            </div>
            <div class="col">
              <label for="" class="form-label">F. Resp</label>
              <input type="number" [className]="contorno[4]" name="fecrespira" #frecuenciaRespiratoria="ngModel" [(ngModel)]="signos.frecuenciaRespiratoria" (focus)="selectAllContent($event)" (blur)="validaInput(signos.frecuenciaRespiratoria,4)" required>
            </div>
            <div class="col">
              <label for="" class="form-label">Alergias</label>
              <input type="text" class="form-control border-success" name="alergias" #alergias="ngModel" [(ngModel)]="signos.alergias" (focus)="selectAllContent($event)" >
            </div>
          </div>   
         
          <div class="row">
            <label for="">Escala de Dolor</label>
            <div class="col" (click)="select(0)" [style.background-color]="background[0]"><fa-icon [icon]="faFaceLaugh" size="sm" style="color: rgb(3, 110, 3); cursor: pointer;" ></fa-icon></div>
            <div class="col" (click)="select(1)" [style.background-color]="background[1]"><fa-icon [icon]="faFaceSmile" size="sm" style="color: green;cursor: pointer;"></fa-icon></div>
            <div class="col" (click)="select(2)" [style.background-color]="background[2]"><fa-icon [icon]="faFaceRollingEyes" size="sm" style="color: rgb(121, 190, 29);cursor: pointer;"></fa-icon></div>
            <div class="col" (click)="select(3)" [style.background-color]="background[3]"><fa-icon [icon]="faFaceMeh" size="sm" style="color: rgb(140, 169, 36);cursor: pointer;"></fa-icon></div>
            <div class="col" (click)="select(4)" [style.background-color]="background[4]"><fa-icon [icon]="faFaceGrimace" size="sm" style="color: rgb(180, 180, 38);cursor: pointer;"></fa-icon></div>
            <div class="col" (click)="select(5)" [style.background-color]="background[5]"><fa-icon [icon]="faFaceFrown" size="sm" style="color: rgb(151, 137, 32);cursor: pointer;"></fa-icon></div>
            <div class="col" (click)="select(6)" [style.background-color]="background[6]"><fa-icon [icon]="faFaceAngry" size="sm" style="color: rgb(151, 93, 32);cursor: pointer;"></fa-icon></div>
            <div class="col" (click)="select(7)" [style.background-color]="background[7]"><fa-icon [icon]="faFaceTired" size="sm" style="color: rgb(174, 37, 37);cursor: pointer;"></fa-icon></div>
            <div class="col" (click)="select(8)" [style.background-color]="background[8]"><fa-icon [icon]="faFaceSadTear" size="sm" style="color: rgb(209, 51, 51);cursor: pointer;"></fa-icon></div>
            <div class="col" (click)="select(9)" [style.background-color]="background[9]"><fa-icon [icon]="faFaceSadCry" size="sm" style="color: rgb(151, 32, 32);cursor: pointer;"></fa-icon></div>
          </div>
          <div class="row">
            <div class="col">
              <label for="">Motivo de Consulta</label>
              <textarea name="motivoConsulta" [className]="contorno[6]" #motivoConsulta="ngModel" [(ngModel)]="signos.motivoConsulta" cols="30" rows="10" (blur)="validaInput(signos.motivoConsulta,9)" required></textarea>
            </div>
          </div>
          <div class="field" style="text-align: right;">
            <button 
              class="btn btn-primary" 
              name="submit" 
              [disabled]="cita._id==='' || userForm.invalid 
                                        || signos.estatura === 0 
                                        || signos.peso===0 
                                        || signos.temperatura===0                                        
                                        || signos.frecuenciaCardiaca ==0
                                        || signos.frecuenciaRespiratoria == 0
                                        || signos.spo2 == 0"
              (click)="guardaSignos()">{{btnAccion}}</button>
              <button type="button" class="btn btn-secondary" (click)="clear()">Limpiar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
