import { Component, OnInit } from '@angular/core';
import { faPlusCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Producto } from 'src/app/models/producto';
import { Global } from 'src/app/service/Global';
import { ProductoService } from 'src/app/service/producto.service';

@Component({
  selector: 'app-lista-productos',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.css'],
  providers:[ProductoService]
})
export class ListaProductosComponent implements OnInit{

  constructor(private productoService:ProductoService){}

  faPlusCircle = faPlusCircle;
  faTrashCan = faTrashCan;
  public productos:Producto[]=[];
  public producto:Producto= new Producto('','','','U',0,0,'',0,0,'',0,true,0,0,0,'',new Date(),true,'','','','','',0,0);
  public count:number=0;
  public pagNumber:number=0;
  public pageNumberSearch:number=0;
  public numeros:number[]=[];
  public currentPage:number=0;
  public HighlightRow:number=-1;
  public codigoBarras:string='';
  public pageClasses:string[]=[];
  public siguienteClass:string='page-item';
  public anteriorClass:string='page-item disabled';
  public isLoadingProd:boolean=false;
  public isAddProduct:boolean=false;
  public action:string=Global.AGREGAR

  ngOnInit(): void {
    this.getListaProductos(Global.PAGE_INIT);
  }

  getListaProductos(page:number):void{
    this.isLoadingProd = true;
    this.productoService.getAllProducts(page)
    .subscribe(res=>{
      this.isLoadingProd = false;
      if(res.status===Global.OK){
        this.productos = res.body.listaProductos.productos;
        this.count = res.body.count.count;
        this.pagNumber = Math.ceil(this.count/Global.PAGE_SIZE_PRODUCTO);
        this.numeros = Array(this.pagNumber).fill(0).map((x,i)=>i);
        this.pageClasses = Array(this.pagNumber).fill('page-link');
        this.pageClasses[page]='page-link disabled';
      }
    });
  }

  gotoPage(page:number):void{
    this.HighlightRow = -1;
    this.currentPage = page;
    this.anteriorClass = 'page-item';
    this.siguienteClass = 'page-item';
    if(this.currentPage===0){
      this.anteriorClass = 'page-item disabled';
    }else if(this.currentPage===this.numeros.length-1){
      this.siguienteClass = 'page-item disabled';
    }
    this.getListaProductos(page);
  }

  next():void{
    this.HighlightRow = -1;
    this.gotoPage(this.currentPage+1);
  }

  prev():void{
    this.HighlightRow = -1;
    this.gotoPage(this.currentPage-1);
  }

  selectRow(index:number):void{
    this.HighlightRow = index;
  }

  editaProducto():void{
    this.producto = this.productos[this.HighlightRow];
    this.action = Global.ACTUALIZAR;
    this.isAddProduct = true;
  }

  busquedaCodBarras():void{
    this.isLoadingProd = true;
    this.productoService.findProductoByCodigoBarras(this.codigoBarras)
    .subscribe(res=>{
      this.isLoadingProd = false;
      if(res.status===Global.OK ){
        this.productos = [];
        this.productos.push(res.body.producto);
      }
    })
  }

  addProduct():void{
    this.isAddProduct = true;
    this.producto = new Producto('','','','U',0,0,'',0,0,'',0,true,0,0,0,'',new Date(),true,'','','','','',0,0);
  }

  cancelProductoAltaUpdt(event:boolean):void{
    this.isAddProduct = false;
    this.producto = new Producto('','','','U',0,0,'',0,0,'',0,true,0,0,0,'',new Date(),true,'','','','','',0,0);
    if(event){
      this.getListaProductos(Global.PAGE_INIT);
    }
  }

}

