<div class="container">
  <div class="card">
    <div class="card-header text-center">
      Gestión de Pacientes
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">          
            <div class="row">
              <div class="col-4">
                <input type="text" class="form-control border-success" [(ngModel)]="nombrePaciente" name="nombrePaciente" placeholder="Nombre" (keyup)="buscarPacienteNombre($event)">
              </div>
              <div class="col-4">
                <input type="text" class="form-control border-success" [(ngModel)]="apellidoPaterno" name="apellidoPaterno" placeholder="Apellido Paterno" (keyup)="buscaPacienteApellido($event)" >
              </div>
              <div class="col-1">
                <button type="button" class="btn btn-outline-primary" (click)="buscarPaciente()" [disabled]="nombrePaciente.length===0 && apellidoPaterno.length===0">Buscar</button>
              </div>
              <div class="col-1">
                <div *ngIf="isBuscandoPaciente" class="spinner-border" role="status">
                  <span class="visually">BB</span>
                </div>
              </div>
              <div class="col-2">
                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                  <input type="checkbox" name="isAutomatica"  [(ngModel)]="isAutomatica" class="btn-check btn-secondary" id="btncheck1" autocomplete="off" (change)="activaBusqueda()">
                  <label class="btn btn-outline-secondary" for="btncheck1">Búsqueda Automática</label>
                </div>
              </div>
            </div>
        </div>
        <div *ngIf="listaPacientes.length > 0" class="row">
          <div class="col">
            <table class="table table-success table-bordered">
              <colgroup>
                <col width="20%"/>
                <col width="20%"/>
                <col width="20%"/>
                <col width="20%"/>
                <col width="15%"/>
                <col width="5%"/>
              </colgroup>
              <thead>
                <tr>
                  <th style="text-align: center;">Nombre</th>
                  <th style="text-align: center;">Apellido Paterno</th>
                  <th style="text-align: center;">Apellido Materno</th>
                  <th style="text-align: center;">Fec Nac</th>
                  <th style="text-align: center;">Teléfono</th>
                  <th style="text-align: left;">
                    <button class="btn btn-sm" (click)="closeListaPacientes()"> <fa-icon [icon]="faClose" size="sm"></fa-icon></button>
                  </th>
                </tr>
              </thead>
            </table>
            <div *ngIf="listaPacientes.length > 0" style="margin-top: -17px;overflow: auto;height: 100px;border: 1px solid green;">
              <table class="table table-bordered table-hover">
                <colgroup>
                  <col width="20%"/>
                  <col width="20%"/>
                  <col width="20%"/>
                  <col width="20%"/>
                  <col width="20%"/>
                </colgroup>
                <tbody>
                  <tr *ngFor="let paciente of listaPacientes; let i = index;" (click)="selectPacienteNombre(i)">
                    <td>{{paciente.nombre}}</td>
                    <td>{{paciente.apellidoP}}</td>
                    <td>{{paciente.apellidoM}}</td>
                    <td>{{paciente.fechaNacimiento | date:'dd-MMM-YYYY'}}</td>
                    <td>{{paciente.telefono}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <hr>
        </div>
      </div>
      <form #manejaPacienteForm="ngForm">
      <div class="container">
        <div class="row">
          <div class="col">
            <div *ngIf="isSavingPaciente" class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually" style="font-size: smaller;">BB</span>
              </div>
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text">
                <fa-icon [icon]="faUser"></fa-icon>
              </span>
                <input type="text" class="form-control border-success" name="nombre" #nombre="ngModel" [(ngModel)]="paciente.nombre" id="" placeholder="Nombre" required>
                <input type="text" class="form-control border-success" name="apellidoP" #apellidoP="ngModel" [(ngModel)]="paciente.apellidoP" placeholder="Apellido Paterno" required>
                <input type="text" class="form-control border-success" name="apellidoM" #apellidoM="ngModel" [(ngModel)]="paciente.apellidoM" placeholder="Apellido Materno" required> 
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text">
                Fecha de Nacimiento
              </span>
              <input type="date" name="paciente.fechaNacimiento" class="form-control" #paciente.fechaNacimiento="ngModel" [(ngModel)]="paciente.dob" >
                
              <span class="input-group-text">
                Sexo:<fa-icon [icon]="faVenusMars"></fa-icon>
              </span>
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="sexo" id="btnradio1" value="M" [(ngModel)]="paciente.sexo" autocomplete="off" [checked]="paciente.sexo=='M'">
                <label class="btn btn-outline-success" for="btnradio1">M</label>
              
                <input type="radio" class="btn-check" name="sexo" id="btnradio2" value="F" [(ngModel)]="paciente.sexo" autocomplete="off" [checked]="paciente.sexo=='F'">
                <label class="btn btn-outline-success" for="btnradio2">F</label>
              </div>
              <span class="input-group-text" id="basic-addon1">
                <fa-icon [icon]="faEnvelope"></fa-icon>
              </span>
                <input type="email" name="email" class="form-control border-success" #correo="ngModel" [(ngModel)]="paciente.correo" placeholder="Correo Electrónico">
            </div>
            <div class="input-group mb-4">
              <span class="input-group-text">
                <fa-icon [icon]="faPhone"></fa-icon>
              </span>
              <input type="tel" name="telefono" class="form-control border-success" #telefono="ngModel" [(ngModel)]="paciente.telefono" placeholder="Teléfono">
              <span class="input-group-text">
                <fa-icon [icon]="faLocationDot"></fa-icon>
              </span>
              <input type="tel" name="localidad" class="form-control border-success" #localidad="ngModel" [(ngModel)]="paciente.codigoPostal" placeholder="Localidad">
              <span class="input-group-text">
                Ocupación<fa-icon [icon]="faPersonCircleQuestion"></fa-icon>
              </span>  
              <select class="form-select border-success" name="ocupacion" [(ngModel)]="paciente.ocupacion">
                <option *ngFor="let ocupacion of ocupaciones" value="{{ocupacion.value}}">
                  {{ocupacion.viewValue}}
                </option>
              </select>
            </div>
          
            <div class="input-group mb-3">
              <span class="input-group-text">
                Raza
              </span>
              <select class="form-select border-success" name="raza" [(ngModel)]="paciente.raza">
                <option *ngFor="let raza of razas" value="{{ raza.value }}">
                  {{ raza.viewValue }}
                </option>
              </select>  
              <span class="input-group-text">
                <fa-icon [icon]="faGraduationCap"></fa-icon>
              </span>
                <select class="form-select border-success" name="escolaridad" [(ngModel)]="paciente.escolaridad">
                  <option *ngFor="let escolaridad of escolaridades" value="{{ escolaridad.value }}">
                    {{ escolaridad.viewValue }}
                  </option>
                </select>
            </div>
            <div class="control" style="text-align: right;">
              <button type="button" class="btn btn-outline-success" (click)="altaUpdatePaciente()" [disabled]="manejaPacienteForm.invalid">{{btnAccion}}</button>
              &nbsp;
              <button type="button" class="btn btn-outline-dark" (click)="limpiar()">Limpiar</button>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</div>
