<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand">
      <img src="assets/images/logoClinicaCorazon.jpg" style="width: 45px;height: auto;">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li *ngIf="isAdmin() || isRecepcion()" class="nav-item">
          <a class="nav-link" *ngIf="isAuthenticated()" aria-current="page" routerLink="/recepcion" [routerLinkActive]="['active']">Recepción <fa-icon [icon]="faCalendarDays"></fa-icon></a>
        </li>
        <li *ngIf="isAdmin() || isEnfermera()" class="nav-item">
          <a class="nav-link" *ngIf="isAuthenticated()" aria-current="page" routerLink="/enfermeria" [routerLinkActive]="['active']">Enfermeria <fa-icon [icon]="faUserNurse"></fa-icon> </a>
        </li>
        <li *ngIf="isAdmin() || isMedico()" class="nav-item">
          <a class="nav-link" *ngIf="isAuthenticated()" aria-current="page" routerLink="/medico" [routerLinkActive]="['active']">Médico <fa-icon [icon]="faStethoscope"></fa-icon> </a>
        </li>
        <li *ngIf="isAdmin() || isMedico()" class="nav-item">
          <a class="nav-link" *ngIf="isAuthenticated()" aria-current="page" routerLink="/consulta" [routerLinkActive]="['active']">Consultar Enfermedad</a>
        </li>
        <li *ngIf="isAdmin() || isFarmacia()" class="nav-item">
          <a class="nav-link" *ngIf="isAuthenticated()" aria-current="page" routerLink="/farmacia" [routerLinkActive]="['active']">Farmacia <fa-icon [icon]="faHouseMedical"></fa-icon></a>
        </li>
        <li *ngIf="isAdmin()" class="nav-item">
          <a class="nav-link" *ngIf="isAuthenticated()" aria-current="page" routerLink="/admon" [routerLinkActive]="['active']">Administración <fa-icon [icon]="faPeopleRoof"></fa-icon> </a>
        </li>
      </ul>
    </div>

    <div *ngIf="isAuthenticated()" class="d-flex" role="search">
      <div class="row">
        <div class="col-10">
          <span class="icon">
            {{nombreUsuario}}
            <fa-icon [icon]="faIcon2Display"></fa-icon>
          </span>
        </div>
        <div class="col-6 text-end">
          <a routerLink="/logout">
            <span class="icon">
              Salir
              <fa-icon [icon]="faRightFromBracket"></fa-icon>
            </span>
          </a>
        </div>
      </div>
      
      
    </div>

  </div>
</nav>
