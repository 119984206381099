import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Compra } from '../models/compra';
import { Global } from './Global';

@Injectable({
  providedIn: 'root'
})
export class ComprasService {

  constructor(private _http:HttpClient) { }

  getAllCompras(offset:number):Observable<any>{
    return this._http.get(Global.urlCompras+'?action=all&offset='+offset+'&size='+Global.PAGE_SIZE_COMPRAS,{observe:'response'})
  }

  addCompra(compra:Compra):Observable<any>{
    return this._http.post(Global.urlCompras,compra,{observe:'response'});
  }

  updateCompra(idCompra:string,compra:Compra):Observable<any>{
    return this._http.put(Global.urlCompras+'?idCompra='+idCompra,compra,{observe:'response'});
  }

  deleteCompra(idCompra:string):Observable<any>{
    return this._http.delete(Global.urlCompras+'?idCompra='+idCompra,{observe:'response'})
  }

}
