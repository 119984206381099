export class Proveedor{
  constructor(
    public _id:string,
    public nombre:string,
    public direccion:string,
    public codigoPostal:string,
    public rfc:string,
    public email:string,
    public telefono:string,
    public personaContacto:string,
    public isActivo:boolean
  ){}
}