<form class="container" style="margin-top: 10px;">
  <div class="row">
    <div class="col">
      <div class="row mb-2 g-2">
        <label for="codigoBarras" class="col-sm-4 col-form-label col-form-label-sm" style="text-align: right;">Código de Barras</label>
        <div class="col-sm-6">
          <input type="text" name="codigoBarras" id="codigoBarras" #codigoBarras="ngModel" [(ngModel)]="producto.codigoBarras" class="form-control form-control-sm border-info">
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="descripcion" class="col-sm-4 col-form-label col-form-label-sm" style="text-align: right;">Descripción</label>
        <div class="col-sm-6">
          <input type="text" name="descripcion" id="descripcion" #descripcion="ngModel" [(ngModel)]="producto.descripcion" class="form-control form-control-sm border-info">
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="sevende" class="col-sm-4 col-form-label col-form-label-sm" style="text-align: right;">Se vende</label>
        <div class="col-sm-2">
          <input type="radio" name="sevende"  value="U" id="sevende1" class="form-check-input border-info" #sevende="ngModel" [(ngModel)]="producto.seVendePor" [checked]="producto.seVendePor==='U'">
          <label for="sevende1">Por Pieza</label>
        </div>
        <div class="col-sm-2">
          <input type="radio" name="sevende" value="G" id="sevende2" class="form-check-input border-info" #sevende="ngModel" [(ngModel)]="producto.seVendePor" [checked]="producto.seVendePor==='G'">
          <label for="sevende2">A granel</label>
        </div>
        <div class="col-sm-3">
          <input type="radio" name="sevende" value="P" id="sevende3" class="form-check-input border-info" #sevende="ngModel" [(ngModel)]="producto.seVendePor" [checked]="producto.seVendePor==='P'"> 
          <label for="sevende3">Como paquete</label>
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="precioCosto" class="col-sm-4 col-form-label col-form-label-sm text-end">Precio Costo</label>
        <div class="col-sm-3">
          <div class="input-group input-group-sm">
            <span class="input-group-text border-info">$</span>
            <input type="number" name="precioCosto" id="precioCosto" #precioCosto="ngModel" [(ngModel)]="producto.precioCosto" class="form-control form-control-sm border-info">
          </div>
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="ganancia" class="col-sm-4 col-form-label col-form-label-sm text-end">Ganancia</label>
        <div class="col-sm-3">
          <div class="input-group input-group-sm">
            <input type="number" name="ganancia" id="ganancia" #ganancia="ngModel" [(ngModel)]="producto.ganancia" class="form-control form-control-sm border-info">
            <span class="input-group-text border-info">%</span>
          </div>
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="precioVenta" class="col-sm-4 col-form-label col-form-label-sm text-end">Precio Venta</label>
        <div class="col-sm-3">
          <div class="input-group input-group-sm">
            <span class="input-group-text border-info">$</span>
            <input type="number" name="precioVenta" id="precioVenta" #precioVenta="ngModel" [(ngModel)]="producto.precioVenta" class="form-control form-control-sm border-info">
          </div>
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="precioMayoreo" class="col-sm-4 col-form-label col-form-label-sm text-end">Precio Mayoreo</label>
        <div class="col-sm-3">
          <div class="input-group input-group-sm">
            <span class="input-group-text border-info">$</span>
            <input type="number" name="precioMayoreo" id="precioMayoreo"  #precioMayoreo="ngModel" [(ngModel)]="producto.precioMayoreo" class="form-control form-control-sm border-info">
          </div>
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="existencia" class="col-sm-4 col-form-label col-form-label-sm text-end ">Existencia</label>
        <div class="col-sm-2">
          <input type="number" style="text-align: center;"  name="existencia" id="existencia" #existencia="ngModel" [(ngModel)]="producto.existencia" class="form-control form-control-sm border-info">
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="minimo" class="col-sm-4 col-form-label col-form-label-sm text-end">Mínimo</label>
        <div class="col-sm-2">
          <input type="number" style="text-align: center;" name="minimo" id="minimo" #minimo="ngModel" [(ngModel)]="producto.minimo" class="form-control form-control-sm border-info">
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="maximo" class="col-sm-4 col-form-label col-form-label-sm text-end">Máximo</label>
        <div class="col-sm-2">
          <input type="number" style="text-align: center;" name="maximo" id="maximo"  #maximo="ngModel" [(ngModel)]="producto.maximo" class="form-control form-control-sm border-info">
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row mb-2 g-2">
        <label for="especialidad" class="col-sm-2 col-form-label col-form-label-sm text-end">Departamento</label>
        <div class="col-sm-4">
          <input type="text" name="especialidad" id="especialidad" #especialidad="ngModel" [(ngModel)]="producto.departamento" class="form-control form-control-sm border-info">
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="proveedor" class="col-sm-2 col-form-label col-form-label-sm text-end">Proveedor</label>
        <div class="col-sm-4">
          <input type="number" name="proveedor" id="proveedor" #proveedor="ngModel" [(ngModel)]="producto.proveedor" class="form-control form-control-sm border-info">
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="lote" class="col-sm-2 col-form-label col-form-label-sm text-end">Lote</label>
        <div class="col-sm-4">
          <input type="text" name="lote" id="lote" #lote="ngModel" [(ngModel)]="producto.lote" class="form-control form-control-sm border-info">
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label for="caducidad" class="col-sm-2 col-form-label col-form-label-sm text-end">Caducidad</label>
        <div class="col-sm-3">
          <input type="date" name="caducidad" id="caducidad" #lote="ngModel" [(ngModel)]="fechaCaducidad" class="form-control form-control-sm border-info">
        </div>
      </div>
      <div class="row mb-2 g-2">
        <div class="col-sm-2 text-end">
          <input type="checkbox" name="usainventario" id="usainventario"  #usainventario="ngModel" [(ngModel)]="producto.usaInventario" class="form-check-input">
        </div>
        <div class="col-sm-4">
          <label for="usainventario" class="col-form-label-sm form-check-label">Este producto si usa inventario.</label>
        </div>
      </div>
      <div class="row mb-2 g-2">
        <label class="col-sm-2 col-form-label-sm text-end">
          Impuestos
        </label>
        <div class="col-sm-4" style="border: 1px solid rgb(13, 202, 240);">
          <div class="row">
            <div class="col form-check">
              <input type="checkbox" name="ieps"  [(ngModel)]="isIeps" id="ieps" class="form-check-input" style="margin-left: auto;">
              <label for="ieps" class="form-check-label" style="font-size: small;">IEPS</label>
            </div>
            <div class="col" style="font-size: small;">8%</div>
          </div>
          <div class="row">
            <div class="col form-check">
              <input type="checkbox" name="iva" [(ngModel)]="isIva" id="iva" class="form-check-input" style="margin-left: auto;">
              <label for="iva" class="form-check-label" style="font-size: small;">IVA</label>
            </div>
            <div class="col" style="font-size: small;">16%</div>
          </div>
        </div>
      </div>
      <div class="row mb-2 g-2">
        <div class="col-sm-2 col-form-label-sm text-end">Clave Prod Serv</div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-3">
              <input type="text" name="claveCatSat" [(ngModel)]="producto.codigoSAT" placeholder="Clave" class="form-control form-control-sm border-info" (focus)="openClaveTable()">
            </div>
            <div class="col-sm-7">
              <input type="text" name="descCatSat" [(ngModel)]="producto.descripcionSAT" placeholder="Descripción" class="form-control form-control-sm border-info" (focus)="openClaveTable()">
            </div>
            <div class="col-sm-2">
              <button type="button" class="btn btn-sm btn-outline-info" (click)="buscaClaveDesc()" >
                <span role="status">Buscar</span>
                <span *ngIf="isLoadingCat" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          <div *ngIf="isTblClaveOpen && claves.length > 0" style="border: 1px solid rgb(13, 202, 240);height: 100px;overflow: auto;">
            <table class="table table-borderless table-hover ">
              <tbody>
                <tr *ngFor="let clave of claves; let i=index" (click)="selectCatalogo(i)">
                  <td style="font-size: small;">{{clave.clave}}</td>
                  <td style="font-size: small;">{{clave.descripcion}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mb-2 g-2">
        <div class="col-sm-2 col-form-label-sm text-end">Unidad de Medida</div>
        <div class="col-sm-5">
          <table class="table table-borderless">
            <colgroup>
              <col width="40%">
              <col width="60%">
            </colgroup>
            <thead>
              <tr>
                <th>
                  <input type="text" class="form-control form-control-sm border-info" placeholder="CLAVE" name="codigoSat" id="codigoSat" [(ngModel)]="producto.claveUnidad" (focus)="openTableUnidad()">
                </th>
                <th>
                  <input type="text" class="form-control form-control-sm border-info" placeholder="DESCRIPCION" name="descUnidadSat" [(ngModel)]="producto.unidad" (focus)="openTableUnidad()">
                </th>
              </tr>
            </thead>
            </table>
            <div *ngIf="isTblUnidadOpen" style="border: 1px solid rgb(13, 202, 240);height: 100px;overflow: auto;margin-top: -20px;">
              <table class="table table-borderless table-hover">
                <colgroup>
                  <col width="40%">
                  <col width="60%">
                </colgroup>
                <tbody>
                  <tr *ngFor="let medida of medidas; let i=index;" (click)="selectUnidad(i)">
                    <td style="font-size: small;text-align: center;">{{medida.clave}}</td>
                    <td style="font-size: small;text-align: left;">{{medida.descripcion}}</td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row text-center">
    <div class="col-sm-6 text-end">
      <button type="submit"  class="btn btn-sm btn-outline-primary" (click)="enviarProducto()" [disabled]="producto.codigoBarras==='' || producto.descripcion=='' || producto.precioCosto==0 || producto.precioVenta==0 || producto.minimo==0 || producto.maximo==0">
        {{action}} Producto&nbsp;<fa-icon [icon]="faPills"></fa-icon></button>
    </div>
    <div class="col-sm-6 text-start">
      <button class="btn btn-sm btn-outline-dark" (click)="emitEvent(false)" >
        Cancelar
      </button>
    </div>
  </div>
</form>
