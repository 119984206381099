<app-header></app-header>
<ul class="nav nav-tabs justify-content-center">
  <li class="nav-item">
    <a *ngIf="tabActivo==='usuario'" class="nav-link active" aria-current="page" aria-disabled="true">Usuario</a>
    <a *ngIf="tabActivo!='usuario'" class="nav-link " aria-current="page"  (click)="clickUsuario('usuario')" style="cursor: pointer;">Usuario</a>
  </li>
  <li class="nav-item">
    <a *ngIf="tabActivo==='paciente'" class="nav-link active" aria-disabled="true" >Paciente</a>
    <a *ngIf="tabActivo!='paciente'" class="nav-link"  (click)="clickUsuario('paciente')" style="cursor: pointer;">Paciente</a>
  </li>
  <li class="nav-item">
    <a *ngIf="tabActivo==='carnet'" class="nav-link active"  aria-disabled="true">Carnet</a>
    <a *ngIf="tabActivo!='carnet'" class="nav-link"  (click)="clickUsuario('carnet')" style="cursor: pointer;">Carnet</a>
  </li>
  <li>
    <a *ngIf="tabActivo==='rol'" class="nav-link active"  aria-disabled="true">Perfiles</a>
    <a *ngIf="tabActivo!='rol'" class="nav-link"  (click)="clickUsuario('rol')" style="cursor: pointer;">Roles</a>
  </li>
  <li>
    <a *ngIf="tabActivo==='medicamento'" class="nav-link active" aria-disabled="true">Medicamentos</a>
    <a *ngIf="tabActivo!='medicamento'" class="nav-link" (click)="clickUsuario('medicamento')" style="cursor: pointer;">Medicamento<fa-icon [icon]="faPills"></fa-icon> </a>
  </li>
  <li>
    <a *ngIf="tabActivo==='sucursal'" class="nav-link active"  aria-disabled="true">Sucursales</a>
    <a *ngIf="tabActivo!='sucursal'" class="nav-link"  (click)="clickUsuario('sucursal')" style="cursor: pointer;">Sucursales</a>
  </li>
  <li>
    <a *ngIf="tabActivo==='historial'" class="nav-link active"  aria-disabled="true">Historial</a>
    <a *ngIf="tabActivo!='historial'" class="nav-link"  (click)="clickUsuario('historial')" style="cursor: pointer;">Historial</a>
  </li>
</ul>
<app-registro *ngIf="tabActivo==='usuario'"></app-registro>
<app-enroll *ngIf="tabActivo==='carnet'"></app-enroll>
<app-paciente *ngIf="tabActivo==='paciente'"></app-paciente>
<app-perfiles *ngIf="tabActivo==='rol'"></app-perfiles>
<app-sucursales *ngIf="tabActivo==='sucursal'"></app-sucursales>
<app-historia-clinica *ngIf="tabActivo==='historial'"></app-historia-clinica>
<app-medicamento *ngIf="tabActivo==='medicamento'"></app-medicamento>