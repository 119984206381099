<ul class="nav nav-tabs justify-content-center">
  <li class="nav-item">
    <a *ngIf="tabActivo==='lista'" class="nav-link active" aria-current="page" aria-disabled="true">Lista Compras</a>
    <a *ngIf="tabActivo!='lista'" class="nav-link" (click)="clickUsuario('lista')" style="cursor: pointer;">Lista Compras</a>
  </li>
  <li class="nav-item">
    <a *ngIf="tabActivo==='proveedor'" class="nav-link active" aria-current="page" aria-disabled="true">Proveedor</a>
    <a *ngIf="tabActivo!='proveedor'" class="nav-link" (click)="clickUsuario('proveedor')" style="cursor: pointer;">Proveedor</a>
  </li>
</ul>
<app-lista-compras *ngIf="tabActivo==='lista'"></app-lista-compras>
<app-lista-proveedor *ngIf="tabActivo==='proveedor'"></app-lista-proveedor>