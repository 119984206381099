<div class="container">
  <div class="card">
    <div class="card-header text-center">
      <div *ngIf="isLoadingMedicos || isSavingCita" class="spinner-border" role="status">
        <fa-icon [icon]="faSpinner"></fa-icon>
      </div>
      <h4>
        Agenda para: {{ medico.nombre }} {{ medico.apellidoP }} - {{ medico.especialidad }}
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="card">
            <form #userForm="ngForm">
              <div class="mb-3" style="text-align: center;">
                <label class="label" style="font-family: 'Courier New', Courier, monospace;font-weight: 200;">Médico</label>
                <select class="form-select mb-3 border-success"
                  aria-label="Default select example"
                  [(ngModel)]="idMedico"
                  name="idMedico"
                  (change)="cambiaMedico($event)">
                  <option *ngFor="let medico of medicos" [ngValue]="medico._id">
                    {{ medico.nombre }} {{ medico.apellidoP }} - {{ medico.especialidad }}
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col" style="text-align: center;">
                  <label for="" class="form-label" style="font-family: 'Courier New', Courier, monospace;">Fecha Cita</label>
                  <input type="date" class="form-control border-success" name="fechaCita" #fechacita="ngModel" [(ngModel)]="fechaCita" (blur)="cambiaFecha()"/>
                </div>
                <div class="col" style="text-align: center;">
                  <label for="" class="form-label" style="font-family: 'Courier New', Courier, monospace;">Hora Cita</label>
                  <input  type="time" [className]="contorno[0]" name="horaCita" #horaCita="ngModel" [(ngModel)]="cita.horaCita" (blur)="validaCampo(cita.horaCita,0)" required />
                </div>
                <div class="col" style="text-align: center;">
                  <label class="form-label" style="font-family: 'Courier New', Courier, monospace;">Duración</label>
                  <select name="duracion" class="form-select mb-3 border-success" [(ngModel)]="cita.duracion" disabled>
                    <option *ngFor="let durac of duracionCita" [ngValue]="durac.value">
                      {{durac.viewValue}}
                    </option>
                  </select>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <input type="text" [className]="contorno[1]" name="nombre" #nombre="ngModel" [(ngModel)]="paciente.nombre" placeholder="Nombre" (keyup)="buscaPacienteMatch($event, 'nombre')"
                      (blur)="validaCampo(paciente.nombre,1)"  required/>
                  </div>
                </div>
                <div class="col">
                    <input type="text" [className]="contorno[2]" name="apellidoP" #apellidoP="ngModel" [(ngModel)]="paciente.apellidoP" (keyup)="buscaPacienteMatch($event, 'apellido')" (blur)="validaCampo(paciente.apellidoP,2)" placeholder="Apellido Paterno" required/>
                    <div *ngIf="listaPacientesApellido.length != 0" style="width: 100%;height: 150px;overflow: auto;margin-top: 0px;border: 2px solid black;">
                      <table class="table is-hoverable is-fullwidth">
                        <thead>
                          <tr>
                            <th style="text-align: center">Paciente</th>
                            <th>
                              <span class="icon-text">
                                <span class="icon">
                                  <a (click)="cerrarApellido()">
                                    <fa-icon [icon]="faCircleXmark"></fa-icon>
                                  </a>
                                </span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let paciente of listaPacientesApellido; let i = index" (click)="selectedRow(i)" style="cursor: pointer">
                            <td>
                              {{ paciente.nombre }} {{ paciente.apellidoP }} - {{ paciente.telefono }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                <div class="col">
                  <input type="text" [className]="contorno[3]" name="apellidoM" #apellidoM="ngModel" [(ngModel)]="paciente.apellidoM"  (blur)="validaCampo(paciente.apellidoM,3)" placeholder="Apellido Materno" required/>
                </div>
              </div>
              <div class="row" *ngIf="listaPacientesNombre.length != 0">
                <div class="col" style="height: 150px;overflow: auto;margin-top: 0px;">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th style="text-align: center">Paciente</th>
                        <th style="text-align: center">Teléfono</th>
                        <th style="text-align: right;">
                          <span class="icon-text">
                            <span class="icon">
                              <a (click)="cerrarNombre()">
                                <fa-icon [icon]="faCircleXmark"></fa-icon>
                              </a>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let paciente of listaPacientesNombre;let i = index"
                        (click)="selectedRow(i)"
                        style="cursor: pointer">
                        <td>
                          {{ paciente.nombre }} {{ paciente.apellidoP }}
                        </td>
                        <td colspan="2">
                          {{ paciente.telefono }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <input type="text" class="form-control border-success"
                        name="telefono"
                        #telefono="ngModel"
                        [(ngModel)]="paciente.telefono"
                        (keyup)="buscaPacienteMatch($event, 'telefono')"
                        placeholder="Teléfono"/>
                      <div
                        *ngIf="listaPacientesTelefono.length != 0"
                        style="width: 100%;
                          height: 150px;
                          overflow: auto;
                          margin-top: 0px;
                          border: 2px solid black;">
                        <table class="table is-hoverable is-fullwidth">
                          <thead>
                            <tr>
                              <th style="text-align: center">Paciente</th>
                              <th>
                                <span class="icon-text">
                                  <span class="icon">
                                    <a (click)="cerrarTelefono()"><fa-icon [icon]="faCircleXmark"></fa-icon></a>
                                  </span>
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let paciente of listaPacientesTelefono;let i = index"
                              (click)="selectedRow(i)"style="cursor: pointer">
                              <td>
                                {{ paciente.nombre }} {{ paciente.apellidoP }} - {{ paciente.telefono }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <input type="text" class="form-control border-success" name="codigoPostal" #localidad="ngModel" [(ngModel)]="paciente.codigoPostal" placeholder="Localidad" >
                    </div>
                  </div>
                  <div class="col">
                    <label for="" style="font-family: 'Courier New', Courier, monospace;">Sexo:</label>&nbsp;
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                      <input type="radio" class="btn-check" name="sexo" id="btnradio1" value="M" [(ngModel)]="paciente.sexo" autocomplete="off" [checked]="paciente.sexo=='M'">
                      <label class="btn btn-outline-success" for="btnradio1">M</label>
                    
                      <input type="radio" class="btn-check" name="sexo" id="btnradio2" value="F" [(ngModel)]="paciente.sexo" autocomplete="off" [checked]="paciente.sexo=='F'">
                      <label class="btn btn-outline-success" for="btnradio2">F</label>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-2">
                    <label class="form-label" style="font-family: 'Courier New', Courier, monospace;">Escolaridad:</label>
                  </div>
                  <div class="col-3">
                    <select class="form-select form-select-sm border-success" name="escolaridad" [(ngModel)]="paciente.escolaridad">
                      <option *ngFor="let escolaridad of escolaridades" value="{{ escolaridad.value }}">
                        {{ escolaridad.viewValue }}
                      </option>
                    </select>
                    
                  </div>
                  <div class="col-7" style="text-align: center;">
                    <label for="" style="font-family: 'Courier New', Courier, monospace;">Fecha de Nacimiento</label>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-2" >
                    <label for="" style="font-family: 'Courier New', Courier, monospace;">Raza:</label>
                  </div>
                  <div class="col-3">
                    <select class="form-select form-select-sm border-success" name="raza" [(ngModel)]="paciente.raza">
                      <option *ngFor="let raza of razas" value="{{ raza.value }}">
                        {{ raza.viewValue }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <input type="text" name="dia" [className]="contorno[4]" #dd="ngModel" [(ngModel)]="dia" placeholder="Día" (blur)="validaCampo(dia,4)" required/>
                  </div>
                  <div class="col-3">
                    <select [className]="contorno[5]" name="mes" [(ngModel)]="mes" (change)="validaCampo(mes,5)">
                      <option *ngFor="let mes of meses" value="{{ mes.value }}">
                        {{ mes.viewValue }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <input type="text" name="anio" [className]="contorno[6]" #yy="ngModel" [(ngModel)]="anio" placeholder="Año" (blur)="validaCampo(anio,6)" required/>
                  </div>
                </div>
            
              <div class="row" >
                <div class="col-2"><label for="" style="font-family: 'Courier New', Courier, monospace;">Ocupación:</label></div>
                <div class="col-6">
                  <select class="form-select form-select-sm border-success" name="ocupacion" [(ngModel)]="paciente.ocupacion">
                    <option *ngFor="let ocupacion of ocupaciones" value="{{ocupacion.value}}">
                      {{ocupacion.viewValue}}
                    </option>
                  </select> 
                </div>
                <div class="col-2">
                  <button class="btn btn-outline-success" style="margin:auto;text-align:right" (click)="addUpdate()" [disabled]="userForm.invalid || mes==''">{{ btnAccion }}</button>
                </div>
                <div class="col-2">
                  <button class="btn btn-outline-dark" style="margin: auto" (click)="limpiar()">Limpiar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="row">
              <div class="col text-end">
                <button class="btn btn-primary" (click)="minusDay()">
                  <fa-icon [icon]="faArrowLeft"></fa-icon>
                </button>
              </div>
              <div class="col" style="text-align: center;">
                <span class="form-label">{{fechaDiaCita | date : "dd-MMM-YYYY" }}</span>
              </div>
              <div class="col text-start">
                <button class="btn btn-primary" (click)="plusDay()">
                  <fa-icon [icon]="faArrowRight"></fa-icon>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex justify-content-center">
                <div *ngIf="isLoadingCitas" class="spinner-border" role="status">
                  <fa-icon [icon]="faSpinner"></fa-icon>
                </div>
              </div>
            </div>
            <table class="table table-dark">
              <colgroup>
                <col width="30%" />
                <col width="25%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
              </colgroup>
              <thead>
                <tr>
                  <th style="text-align: center; font-size: small">Paciente</th>
                  <th style="text-align: center; font-size: small">Teléfono</th>
                  <th style="text-align: center; font-size: small">H. Ini</th>
                  <th style="text-align: center; font-size: small">H. Fin</th>
                  <th style="text-align: center; font-size: small">Acción</th>
                </tr>
              </thead>
            </table>
            <div style="width: 100%;text-align: center;margin-top: -17px;height: 350px;overflow: auto;">
              <table class="table table-bordered table-hover" style="width: 100%">
                <colgroup>
                  <col width="30%" />
                  <col width="25%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                </colgroup>
                <tbody>
                  <tr *ngFor="let cita of citas; let i = index" [class.interconsulta]="cita.isInterconsulta && cita.horaCita===''">
                    <td>
                      {{ cita.paciente.nombre }} {{ cita.paciente.apellidoP }}
                    </td>
                    <td>{{ cita.paciente.telefono }}</td>
                    <td>{{ cita.horaCita }}</td>
                    <td>{{ cita.horaCitaDuracion }}</td>
                    <td *ngIf="cita.isAtendido">
                      <fa-icon [icon]="faFlagCheckered"> </fa-icon>
                    </td>
                    <td *ngIf="!cita.isAtendido">
                      <span class="icon is-small is-left">
                        <button class="btn btn-sm border-info" (click)="editarCita(i)">
                          <fa-icon [icon]="faPencil"></fa-icon>
                        </button>
                      </span>
                      &nbsp;
                      <span class="icon is-small is-left">
                        <button class="btn btn-sm border-info" (click)="borrarCita(i)">
                          <fa-icon [icon]="faTrashCan"></fa-icon>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="listaPacientesCarnet.length > 0" class="modal show modal-dialog-centered" tabindex="-1" style="display: block;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Carnet {{carnet.folio}} - Citas disponibles: {{carnet.noConsultasDisponibles}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Nacimiento</th>
              <th>Sexo</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let paciente of carnet.pacientes; let i = index" (click)="selectRow(i)" [class.selected]="i == higlightIndex">
              <td>{{paciente.nombre}}</td>
              <td>{{paciente.apellidoP}}</td>
              <td>{{paciente.fechaNacimiento | date:'dd-MMM-YYYY'}}</td>
              <td>{{paciente.sexo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="seleccionarPaciente()">Seleccionar Paciente</button>
      </div>
    </div>
  </div>
</div>

