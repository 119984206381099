<section class="vh-100" style="background-color: #618b9a;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-10">
        <div class="card" style="border-radius: 1rem;">
          <div class="row g-0">
            <div class="col-md-6 col-lg-5 d-none d-md-block">
              <img src="../../../assets/images/logobyb.jpeg"
                alt="login form" class="img-fluid" style="border-radius: 1rem 0 0 1rem;" />
            </div>
            <div class="col-md-6 col-lg-7 d-flex align-items-center">
              <div class="card-body p-4 p-lg-5 text-black">
                <form>
                  <div class="fw-normal mb-3 pb-1 text-center">
                    <span class="h1 fw-bold mb-0 ">Clínica B&B</span>
                  </div>

                  <h5 class="fw-normal mb-3 pb-3 text-center" style="letter-spacing: 1px;">Inicia Sesión en tu cuenta</h5>

                  <div class="mb-4">
                    <input type="email" name="username" [(ngModel)]="user.email" class="form-control form-control-lg" placeholder="Correo Electrónico"/>
                  </div>

                  <div class="mb-4">
                    <input type="password" name="password" [(ngModel)]="user.password" class="form-control form-control-lg" placeholder="Contraseña" />
                  </div>

                  <div class="pt-1 mb-4">
                    <button class="btn btn-dark btn-lg btn-block" type="button" (click)="signIn()">Entrar</button>
                  </div>
                  <a class="small text-muted" routerLink="/reset">Olvidaste tu Contraseña ?</a>
                  <div style="text-align: right;color:cornflowerblue;" class="small">Versión:{{version}}</div>
                </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>