import { Component, OnInit } from '@angular/core';
import { Global } from 'src/app/service/Global';


@Component({
  selector: 'app-farmacia',
  templateUrl: './farmacia.component.html',
  styleUrls: ['./farmacia.component.css'],
})
export class FarmaciaComponent {
  
  public tabActivo: string = Global.TAB_VENTA;

  clickUsuario(tabNombre:string):void{
    this.tabActivo = tabNombre;
  }

}
