import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Proveedor } from 'src/app/models/proveedor';
import { Global } from 'src/app/service/Global';
import { ProveedorService } from 'src/app/service/proveedor.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styleUrls: ['./proveedor.component.css'],
  providers: [ProveedorService]
})
export class ProveedorComponent {
  constructor(private proveedorService:ProveedorService){}
  @Input() action:string='';
  @Input() proveedor:Proveedor = new Proveedor('','','','','','','','',false);
  @Output() respuesta = new EventEmitter<boolean>();


  altaEditaProveedor():void{
    if(this.action===Global.AGREGAR){
      this.altaEditaProveedor();
    }else if(this.action===Global.ACTUALIZAR){
      this.editaProveedor();
    }
  }

  altaProveedor():void{
    Swal.fire({
      titleText:'Está seguro que desea agregar éste proveedor?',
      showCancelButton:true,
      confirmButtonText:'Si'
    })
    .then(response=>{
      if(response.isConfirmed){
        this.proveedorService.addProveedor(this.proveedor)
        .subscribe(res=>{
          if(res.status===Global.OK){
            Swal.fire({
              text:'El proveedor se agregó correctamente!',
              timer:Global.TIMER_OFF,
              icon:'success'
            });
            this.emitEvent(true);
          }
        })
      }
    })
  }

  editaProveedor():void{
  Swal.fire({
      titleText:'Está seguro que desea editar éste proveedor?',
      showCancelButton:true,
      confirmButtonText:'Si'
    })
    .then(response=>{
      if(response.isConfirmed){
        this.proveedorService.updateProveedor(this.proveedor)
        .subscribe(res=>{
          if(res.status===Global.OK){
            Swal.fire({
              titleText:'El proveedor ha sido actualizado',
              icon:'success',
              timer:Global.TIMER_OFF
            })
            this.emitEvent(true);
          }
        });
      }
    })
  }

  emitEvent(isReload:boolean):void{
    this.respuesta.emit(isReload);
  }

}
