import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Proveedor } from '../models/proveedor';
import { Observable } from 'rxjs';
import { Global } from './Global';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService {

  constructor(private _http:HttpClient) { }

  addProveedor(proveedor:Proveedor):Observable<any>{
    return this._http.post(Global.urlProveedor,proveedor,{observe:'response'});
  }

  getAllProveedores(offset:number):Observable<any>{
    return this._http.get(Global.urlProveedor+'?action=all&size='+Global.PAGE_SIZE_PROVEEDOR+'&offset='+offset,{observe:'response'});
  }

  getProveedorByDesc(desc:string):Observable<any>{
    return this._http.get(Global.urlProveedor+'?action=desc&desc='+desc,{observe:'response'});
  }

  updateProveedor(proveedor:Proveedor):Observable<any>{
    return this._http.put(Global.urlProveedor+'/'+proveedor._id,proveedor,{observe:'response'});
  }

  deleteProveedor(idProveedor:string):Observable<any>{
    return this._http.delete(Global.urlProveedor+'/'+idProveedor+'?action=delete',{observe:'response'});
  }

}
