<div *ngIf="!isAddProveedor" class="container">
  <nav class="navbar bg-body-tertiary">
    <div class="container-fluid">
      <form class="d-flex" role="search">
        <input class="form-control me-2" type="search" name="descProv" [(ngModel)]="descProv" placeholder="Nombre Proveedor" aria-label="Search">
        <button class="btn btn-outline-info" type="button" (click)="busquedaProveeDesc()">
          <span role="status">Búsqueda</span>
          <span *ngIf="isLoadingProv" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        </button>
        <button type="button" class="btn btn-outline-info" (click)="getListaProveedores(0)">Limpiar</button>
      </form>
      <button class="btn btn-outline-primary" (click)="addProveedor()">Agregar Proveedor&nbsp;<fa-icon [icon]="faPlusCircle"></fa-icon> </button>
      <button class="btn btn-outline-primary" (click)="deleteProveedor()" [disabled]="HighlightRow===-1">Eliminar Proveedor&nbsp;<fa-icon [icon]="faMinusCircle"></fa-icon> </button>
    </div>
  </nav>
  <table class="table table-info">
    <colgroup>
      <col width="20%">
      <col width="20%">
      <col width="10%">
      <col width="15%">
      <col width="12%">
      <col width="13%">
      <col width="10%">
    </colgroup>
    <thead>
      <tr>
        <th class="producto-head">Nombre</th>
        <th class="producto-head">Dirección</th>
        <th class="producto-head">Código Postal</th>
        <th class="producto-head">Tel</th>
        <th class="producto-head">Correo</th>
        <th class="producto-head">Contacto</th>
        <th class="producto-head">Estatus</th>
      </tr>
    </thead>
  </table>
  <div style="margin-top: -16px;height: 400px;overflow: auto;border: 1px solid black;">
    <table class="table table-hover table-striped table-bordered">
      <colgroup>
        <col width="20%">
        <col width="20%">
        <col width="10%">
        <col width="15%">
        <col width="12%">
        <col width="13%">
        <col width="10%">
      </colgroup>
      <tbody>
        <tr *ngFor="let proveedor of proveedores; let i=index" [class.selected]="i===HighlightRow" style="cursor: pointer;" (click)="selectRow(i)" (dblclick)="editaProveedor(i)">
          <td class="producto">{{proveedor.nombre}}</td>
          <td class="producto">{{proveedor.direccion}}</td>
          <td class="producto">{{proveedor.codigoPostal}}</td>
          <td class="producto">{{proveedor.telefono}}</td>
          <td class="producto">{{proveedor.email}}</td>
          <td class="producto">{{proveedor.personaContacto}}</td>
          <td class="producto">{{proveedor.isActivo?'Activo':'Inactivo'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <nav *ngIf="count===0" aria-label="...">
    <ul class="pagination justify-content-center">
      <li [className]="anteriorClass">
        <button class="page-link" (click)="prev()">Ant</button>
      </li>
      <li *ngFor="let numero of numeros" class="page-item">
        <button [className]="pageClasses[numero]" (click)="gotoPage(numero)">{{(numero+1)}}</button>
      </li>
      <li [className]="siguienteClass">
        <button class="page-link" (click)="next()">Sig</button>
      </li>
    </ul>
  </nav>
</div>
<app-proveedor *ngIf="isAddProveedor" [proveedor]="proveedor" [action]="action" (respuesta)="cancelProvAltaUpdt($event)"></app-proveedor>
