import { Component } from '@angular/core';

@Component({
  selector: 'app-compra-producto',
  templateUrl: './compra-producto.component.html',
  styleUrls: ['./compra-producto.component.css']
})
export class CompraProductoComponent {

}
