import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPills } from '@fortawesome/free-solid-svg-icons';
import { CatProdServ } from 'src/app/models/catprodserv';
import { Producto } from 'src/app/models/producto';
import { Global } from 'src/app/service/Global';
import { ProductoService } from 'src/app/service/producto.service';
import Swal from 'sweetalert2';

interface Medida{
  clave:string,
  descripcion:string
}
@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css'],
  providers:[ProductoService]
})
export class ProductoComponent implements OnInit{
  
  constructor(private productoService:ProductoService){}
  faPills = faPills;
  @Input() action:string='';
  @Input() producto:Producto = new Producto('','','','U',0,0,'',0,0,'',0,true,0,0,0,'',new Date(),true,'','','','','',0,0);
  @Output() respuesta= new EventEmitter<boolean>();
  public medidas: Medida[] = [
    {clave:'H87',descripcion:'Pieza'},
    {clave: 'EA',descripcion:'Elemento'},
    {clave: 'E48',descripcion:'Unidad de Servicio'},
    {clave: 'ACT',descripcion:'Actividad'},
    {clave: 'KGM',descripcion:'Kilogramo' },
    {clave: 'E51',descripcion:'Trabajo'},
    {clave: 'A9', descripcion:'Tarifa' },
    {clave: 'MTR',descripcion:'Metro'},
    {clave: 'AB',descripcion:'Paquete a granel'},
    {clave: 'BB',descripcion:'Caja base'},
    {clave: 'KT',descripcion:'Kit'},
    {clave: 'SET',descripcion:'Conjunto'},
    {clave: 'LTR',descripcion:'Litro'},
    {clave: 'XBX',descripcion:'Caja'},
    {clave: 'MON',descripcion:'Mes'},
    {clave: 'HUR',descripcion:'Hora'},
    {clave: 'MTK',descripcion:'Metro cuadrado'},
    {clave: '11',descripcion:'Equipos'},
    {clave: 'MGM',descripcion:'Miligramo'},
    {clave: 'XPK',descripcion:'Paquete'},
    {clave: 'XKI',descripcion:'Kit (Conjunto de piezas)'},
    {clave: 'AS',descripcion:'Variedad'},
    {clave: 'GRM',descripcion:'Gramo'},
    {clave: 'PR',descripcion:'Par'},
    {clave: 'DPC',descripcion:'Docenas de piezas'},
    {clave: 'xun',descripcion:'Unidad'},
    {clave: 'DAY',descripcion:'Día'},
    {clave: 'XLT',descripcion:'Lote'},
    {clave: '10',descripcion:'Grupos'},
    {clave: 'MLT',descripcion:'Mililitro'},
    {clave: 'E54',descripcion:'Viaje'}	
  ]

  public isTblUnidadOpen:boolean=false;
  public isTblClaveOpen:boolean=false;
  public claves:CatProdServ[]=[];
  public isLoadingCat:boolean=false;
  public isIva:boolean=false;
  public isIeps:boolean=false;
  public fechaCaducidad:string='';
  ngOnInit(): void {
    this.isIeps = this.producto.ieps != 0;
    this.isIva = this.producto.iva != 0;
    this.fechaCaducidad = this.producto.caducidad.toString().substring(0,10);
  }

  selectUnidad(index:number):void{
    this.producto.claveUnidad = this.medidas[index].clave;
    this.producto.unidad = this.medidas[index].descripcion;
    this.isTblUnidadOpen = false;
  }

  openTableUnidad():void{
    this.isTblUnidadOpen = true;
  }

  openClaveTable():void{
    this.isTblClaveOpen = true;
  }

  enviarProducto():void{
    if(this.action===Global.AGREGAR){
      this.altaProducto();
    }else if(this.action===Global.ACTUALIZAR){
      this.updateProducto();
    }
  }

  altaProducto():void{
    Swal.fire({
      titleText:'Está seguro que desea agregar éste producto?',
      showCancelButton:true,
      confirmButtonText:'Si'
    })
    .then(response=>{
      if(response.isConfirmed){
        this.producto.iva = this.isIva ? Global.IVA : 0;
        this.producto.ieps = this.isIeps ? Global.IEPS : 0;
        if(this.fechaCaducidad!=''&& this.fechaCaducidad!=undefined){
            this.producto.caducidad = this.transformDate(this.fechaCaducidad);
        }
        this.productoService.addProducto(this.producto)
        .subscribe(res=>{
          if(res.status===Global.OK){
            Swal.fire({
              text:'El producto se agregó correctamente!',
              timer:Global.TIMER_OFF,
              icon:'success'
            });
            this.emitEvent(true);
          }
        })
      }
    })
  }

  updateProducto():void{
    Swal.fire({
      titleText:'Está seguro que desea actualizar éste producto?',
      showCancelButton:true,
      confirmButtonText:'Si'
    })
    .then(response=>{
      if(response.isConfirmed){
        this.producto.iva = this.isIva ? Global.IVA : 0;
        this.producto.ieps = this.isIeps ? Global.IEPS : 0;
        if(this.fechaCaducidad!=''&& this.fechaCaducidad!=undefined){
          this.producto.caducidad = this.transformDate(this.fechaCaducidad);
        }
        this.productoService.updateProduct(this.producto)
        .subscribe((res:any)=>{
          if(res.status===Global.OK){
            Swal.fire({
              text:'El producto se actualizó correctamente!',
              icon:'success',
              timer:Global.TIMER_OFF
            });
            this.emitEvent(true);
          }
        })
      }
    })
  }

  selectCatalogo(index:number):void{
    this.producto.codigoSAT = this.claves[index].clave;
    this.producto.descripcionSAT = this.claves[index].descripcion;
    this.claves = [];
    this.isTblClaveOpen = false;
  }

  buscaClaveDesc():void{
    this.isLoadingCat = true;
    this.productoService.obtieneCatalogoSat(this.producto.descripcionSAT)
    .subscribe((res:any)=>{
      this.isLoadingCat=false;
      if(res.status==Global.OK && res.body.catalogo.length>0){
        this.claves = res.body.catalogo;
      }else if(res.body.catalogo.length===0){
        Swal.fire({
          text:'No se encontraron coincidencias',
          icon:'warning',
          timer:Global.TIMER_OFF
        })
      }
    })
  }

  emitEvent(isReload:boolean):void{
    this.respuesta.emit(isReload);
  }

  transformDate(inputDate:string):Date{
    let fecCad = new Date();
    let splitFecDac = inputDate.split('-');
    fecCad.setFullYear(Number(splitFecDac[0]));
    fecCad.setMonth(Number(splitFecDac[1])-1);
    fecCad.setDate(Number(splitFecDac[2]));
    fecCad.setHours(23);
    fecCad.setMinutes(59);
    fecCad.setSeconds(59);
    return fecCad;
  }

}
