import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-compras',
  templateUrl: './menu-compras.component.html',
  styleUrls: ['./menu-compras.component.css']
})
export class MenuComprasComponent {

  public tabActivo:string='';

  clickUsuario(tabNombre:string):void{
    this.tabActivo = tabNombre;
  }
}
