import { Component, OnInit } from '@angular/core';
import { Compra } from 'src/app/models/compra';
import { ComprasService } from 'src/app/service/compras.service';
import { Global } from 'src/app/service/Global';

@Component({
  selector: 'app-lista-compras',
  templateUrl: './lista-compras.component.html',
  styleUrls: ['./lista-compras.component.css'],
  providers:[ComprasService]
})
export class ListaComprasComponent implements OnInit{
  
  constructor(private comprasService:ComprasService){}
  public compras:Compra[]=[];
  public compra:Compra={} as Compra;
  ngOnInit(): void {
    this.getAllComprasPage(Global.PAGE_INIT);
  }

  getAllComprasPage(page:number):void{
    this.comprasService.getAllCompras(page)
    .subscribe(res=>{
      console.log(res);
      if(res.status===Global.OK){

      }
    });
  }

}
