<div class="container">
  <h2 class="text-center">Sistema de Facturación de B&B</h2>
  <div class="row">
    <div *ngIf="venta._id===''" class="col">
      <form class="container" #ventaForm="ngForm">
        <div class="row mb-3">
          <label for="inpuTicket" class="col-sm-4 text-end col-form-label">No Ticket:</label>
          <div class="col-sm-2">
            <input type="text" class="form-control" id="inpuTicket" name="ticket" [(ngModel)]="buscaVenta.ticket" required>
          </div>
        </div>
        <div class="row mb-3">
          <label for="idSuc" class="col-sm-4 text-end">Sucursal:</label>
          <div class="col-sm-2">
            <input type="text" class="form-control" id="idSuc" name="sucursal"  [(ngModel)]="buscaVenta.sucursal" required>
          </div>
        </div>
        <div class="row mb-3">
          <label for="idFecha" class="col-sm-4 text-end">Fecha:</label>
          <div class="col-sm-2">
            <input type="date" class="form-control" id="idFecha" name="fecha" #fecha="ngModel" [(ngModel)]="buscaVenta.fecha" required>
          </div>
        </div>
        <div class="row mb-3">
          <label for="idTotal" class="col-sm-4 text-end">Total:</label>
          <div class="col-sm-2">
            <input type="number" class="form-control" id="idTotal" name="total" #total="ngModel" [(ngModel)]="buscaVenta.total" required>
          </div>
        </div>
        <div class="row g-1">
          <div class="col-5 text-end">
            <button type="submit" class="btn btn-primary " (click)="buscarVenta()" [disabled]="ventaForm.invalid || buscaVenta.total<=0" >
              <span role="status">Buscar</span>  
              <span *ngIf="isLoadingVenta" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </button>
          </div>
          <div class="col-7">
            <button class="btn btn-info" (click)="limpiarBusquedaVenta()">Limpiar</button>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="venta._id!=''" class="col">
      <div class="row mb-1 g-1">
        <label class="col-sm-2 text-end factura-datos">Facturado:</label>
        <div *ngIf="!venta.isFacturado" class="col-sm-1 text-center facturado-false">NO</div>
        <div *ngIf="venta.isFacturado" class="col-sm-1 text-center facturado-true">SI</div>
        <label class="col-sm-3 text-end factura-datos">Fecha Facturación:</label>
        <label class="col-sm-3 factura-datos-bold">{{venta.fechaFacturado | date:'dd-MM-YYYY hh:mm a':'GMT'}}</label>
      </div>
      <div class="row mb-1 g-1">
        <label class="col-sm-2 text-end factura-datos" >Fecha Compra:</label>
        <div class="col-sm-2 factura-datos-bold">{{venta.fechaVenta | date:'dd-MM-YYYY'}}</div>
        <label class="col-sm-2 text-end factura-datos">No Ticket:</label>
      <div class="col-sm-3 factura-datos-bold">{{venta.noTicket}}</div>
    
      <table class="table table-sm table-bordered">
        <colgroup>
          <col width="15%">
          <col width="45%">
          <col width="15%">
          <col width="15%">
        </colgroup>
        <thead>
          <tr>
            <th class="factura-center-medium">Cantidad</th>
            <th class="factura-center-medium">Descripción</th>
            <th class="factura-center-medium">Clave Unidad</th>
            <th class="factura-center-medium">Valor unitario</th>
          </tr>
        </thead>
      </table>
      <div  style="margin-top: -16px;height: 170px;overflow: auto;border: 1px solid blue;">
        <table class="table table-borderless">
          <colgroup>
            <col width="15%">
            <col width="45%">
            <col width="15%">
            <col width="15%">
          </colgroup>
          <tbody>
            <tr *ngFor="let ventaProducto of venta.ventaProducto">
              <td class="factura-center-medium">{{ventaProducto.cantidad}}</td>
              <td class="factura-center-medium">{{ventaProducto.producto.descripcion | wordLength:20}}</td>
              <td class="factura-center-medium"></td>
              <td class="factura-center-medium">{{ventaProducto.producto.precioVenta/1.16 * ventaProducto.cantidad | currency}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="factura-right-medium" colspan="3">Descuento:</td>
              <td class="factura-center-medium">$0.00</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td class="factura-right-medium">Sub Total:</td>
              <td class="factura-center-medium">{{venta.total/1.16 | currency}}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td class="factura-right-medium">Iva:</td>
              <td class="factura-center-medium">{{venta.total/1.16*0.16 | currency}}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td class="factura-right-medium">Total</td>
              <td class="factura-center-medium">{{venta.total | currency}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      
    </div>
  </div>   

  <section *ngIf="venta._id!='' && !venta.isFacturado">
    <h6 class="text-end">Si ya llenaste tus datos previamente, ingresa tu RFC y presiona ENTER</h6>
    <div class="row">
      <div class="col">
        <form class="row g-3 needs-validation was-validated"  novalidate #userForm="ngForm">
          <div class="col-md-6 text-center">
            <label for="inputNombre" class="form-label col-form-label-sm mb-0">Razón Social</label>
            <input name="nombre" id="inputNombre" type="text" class="form-control form-control-sm" #nombre="ngModel" [(ngModel)]="receptor.Nombre" pattern="^.{4,100}" required>
          </div>
          <div class="col-md-6 text-center">
            <label for="inputRfc" class="form-label col-form-label-sm mb-0">
              RFC
              <span *ngIf="isLoadingRfc" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </label>
            <input 
              name="rfc" 
              id="inputRfc" 
              type="text" 
              class="form-control form-control-sm" 
              #rfc="ngModel" 
              [(ngModel)]="receptor.Rfc" 
              (keyup)="buscaDatosFactura($event)"
              pattern="^\w{4,4}\d{6,6}\w{3,3}$" required>
            <!--div class="invalid-feedback">
              Favor de llenar el RFC!
            </div-->        
          </div>
          <div class="col-md-6 text-center">
            <label for="inputCP" class="form-label col-form-label-sm mb-0">Domicilio Fiscal (Código Postal):</label>
            <input name="codigoPostal" id="inputCP" type="text" class="form-control form-control-sm" #codigoPostal="ngModel" [(ngModel)]="receptor.DomicilioFiscalReceptor" pattern="\d{5,5}" required>
          </div>
          <div class="col-md-6 text-center">
            <label for="inputEmail" class="form-label col-form-label-sm mb-0">Correo Electrónico:</label>
            <input name="correoelectronico" id="inputEmail" type="email" class="form-control form-control-sm" #email="ngModel" [(ngModel)]="receptor.email" pattern='^([^.@]+)(\.[^.@]+)*@([^.@]+\.)+([^.@]+)$' required>
          </div>
          <!--div class="col-md-6 text-center">
            <label for="inputFP" class="form-label col-form-label-sm mb-0">Forma de Pago:</label>
            <select name="formaPago" id="inputFP" class="form-select form-select-sm" aria-label="Select FP" [(ngModel)]="formaPago" required>
              <option *ngFor="let formaPago of listaFormaPago" [ngValue]="formaPago.formapago">
                {{formaPago.descripcion}}
              </option>
            </select>
            <div class="valid-feedback">
              OK!
            </div> 
          </div-->
          <div class="col-md-6 text-center">
            <label for="inputRF" class="form-label col-form-label-sm mb-0">Régimen Fiscal:</label>
            <select name="regimenFiscal" 
                    id="inputRF" 
                    (change)="buscaUsoCfdi($event)"
                    class="form-select form-select-sm" 
                    [(ngModel)]="receptor.RegimenFiscalReceptor" 
                    required>
              <option *ngFor="let regimenFiscal of listaRegimenFiscal" [ngValue]="regimenFiscal.regimenfiscal">
                {{regimenFiscal.descripcion}}
              </option>
            </select> 
          </div>
          <div class="col-md-6 text-center">
            <label for="inputUsoCFDI" class="form-label col-form-label-sm mb-0">Uso del CFDI:</label>
            <select name="usoCfdi" id="inputUsoCFDI" class="form-select form-select-sm mb-3" [(ngModel)]="receptor.UsoCFDI" required>
              <option *ngFor="let usoCfdi of listaUsoCfdiFiltrado" [ngValue]="usoCfdi.usoCfdi">
                {{usoCfdi.descripcion}}
              </option>
            </select>
          </div>
          <div class="col-md-12 text-end">
            <button class="btn btn-outline-success" type="submit" (click)="submit()" [disabled]="userForm.invalid || venta.isFacturado">Solicitar Factura</button>
            <button class="btn btn-outline-info" (click)="limpiar()">Limpiar</button>
          </div>
        </form>
      </div>
      
    </div>
    
  </section>
</div>
