<table style="width: 100%;">
  <thead>
    <tr>
      <th style="font-family: cursive;padding: 5px;">
        <img src="../../../assets/images/logobyb.jpeg" width="50px" alt="">
      </th>
      <th style="text-align: right; font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">Consultorio B&B</th>
    </tr>
  </thead>
</table>
<table style="width: 90%;height: 40px;">
  <colgroup>
    <col width="65%">
    <col width="15%">
    <col width="20%">
  </colgroup>
  <thead> 
    <tr>
      <th style="color: red;text-align: right;">No. {{cita.noReceta}}</th>
      <th style="text-align: right;color: darkseagreen;">Fecha:</th>
      <th class="fecha-cita">{{fechaHoy}}</th>
    </tr>
  </thead>
</table>
<table style="width: 95%;">
  <colgroup>
    <col width="8%">
    <col width="33%">
    <col width="5%">
    <col width="6%">
    <col width="5%">
    <col width="6%">
    <col width="5%">
    <col width="9%">
    <col width="9%">
    <col width="13%">
  </colgroup>
  <tbody>
    <tr>
      <td class="paciente-texto">Nombre:</td>
      <td class="paciente-dato">{{cita.paciente.nombre}} {{cita.paciente.apellidoP}} {{cita.paciente.apellidoM}}</td>
      <td class="paciente-texto">Edad:</td>
      <td class="paciente-dato">{{edad}}</td>
      <td class="paciente-texto">Peso:</td>
      <td class="paciente-dato">{{cita.signos[0].peso}}Kg</td>
      <td class="paciente-texto">Talla:</td>
      <td class="paciente-dato">{{cita.signos[0].estatura}}cm</td>
      <td class="paciente-texto">Alergias:</td>
      <td class="alergias">
        <input type="radio" name="alergias" id="si" [checked]="cita.signos[0].alergias!=''"><label class="label-alergias">Si</label> &nbsp;
        <input type="radio" name="alergias" id="no" [checked]="cita.signos[0].alergias===''"><label class="label-alergias">No</label></td>
    </tr>
  </tbody>
</table>
<table style="width: 100%;height: 60px;">
  <colgroup>
    <col width="20%">
    <col width="80%">
  </colgroup>
  <tbody>
    <tr>
      <td class="receta-signos">Diagnóstico Médico:&nbsp;</td>
      <td class="diagnostico">{{cita.diagnostico}}</td>
    </tr>
  </tbody>
</table>
<div class="container text-center" style="height: 300px;">
  <div class="row justify-content-start">
    <div class="col-8">
      <div class="row">
        <div class="col">
          <ol>
            <li *ngFor="let medicamento of cita.medicamentoReceta; let i=index" style="text-align: left; font-family: 'Courier New', Courier, monospace;font-size: small;"><strong>{{medicamento.nombre}}</strong> {{medicamento.prescripcion}}</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="col-4" style="margin-top: -50px;">
      <div class="row">
        <div class="col receta-signos">Cuales</div>
        <div class="col receta-signos-dato" style="border-bottom: 1px solid darkseagreen;">{{cita.signos[0].alergias}}</div>
      </div>
      <div class="row">
        <div class="col receta-signos">FC</div>
        <div class="col receta-signos-dato" style="border-bottom: 1px solid darkseagreen;">{{cita.signos[0].frecuenciaCardiaca}}ppm</div>
      </div>
      <div class="row">
        <div class="col receta-signos">FR</div>
        <div class="col receta-signos-dato" style="border-bottom: 1px solid darkseagreen;">{{cita.signos[0].frecuenciaRespiratoria}}</div>
      </div>
      <div class="row">
        <div class="col receta-signos">T/A</div>
        <div class="col receta-signos-dato" style="border-bottom: 1px solid darkseagreen;">{{cita.signos[0].presionDias}}/{{cita.signos[0].presionSis}}mmHg</div>
      </div>
      <div class="row">
        <div class="col receta-signos">Temp</div>
        <div class="col receta-signos-dato" style="border-bottom: 1px solid darkseagreen;">{{cita.signos[0].temperatura}}&deg;C</div>
      </div>
      <div class="row">
        <div class="col receta-signos">Glucosa</div>
        <div class="col receta-signos-dato" style="border-bottom: 1px solid darkseagreen;">{{cita.signos[0].glucotest}}</div>
      </div>
      <div class="row">
        <div class="col receta-signos">SPO2</div>
        <div class="col receta-signos-dato" style="border-bottom: 1px solid darkseagreen;">{{cita.signos[0].spo2}}%</div>
      </div>
    </div>
  </div>
</div>
<table style="width: 95%;height: 450px;">
  <thead>
    <tr style="border-top: 2px dotted black;">
      <th></th>
      <th style="text-align: center;">Recomendaciones Generales</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td colspan="3">
        <ol>
          <li *ngFor="let tratamiento of cita.tratamiento">{{tratamiento}}</li>
        </ol>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td class="datos-footer-medico">{{cita.medico.nombre}} {{cita.medico.apellidoP}} - {{this.cita.medico.cedula}}</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td class="datos-footer-cedula">Nombre, Firma y CED. PROF.</td>
      <td class="datos-footer-direccion">I. Zaragoza No 5 Barrio Santiaguito, Ocoyoacac. CP:52740, México</td>
      <td class="datos-footer-whatsapp"><fa-icon [icon]="faWhatsapp"></fa-icon> 722 131 7862</td>
    </tr>
  </tfoot>
</table>