import { Component, OnInit } from '@angular/core';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { Proveedor } from 'src/app/models/proveedor';
import { Global } from 'src/app/service/Global';
import { ProveedorService } from 'src/app/service/proveedor.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-proveedor',
  templateUrl: './lista-proveedor.component.html',
  styleUrls: ['./lista-proveedor.component.css'],
  providers:[ProveedorService]
})
export class ListaProveedorComponent implements OnInit {

  constructor(private proveedorService:ProveedorService){}
  
  ngOnInit(): void {
    this.getListaProveedores(Global.PAGE_INIT);
  }

  public proveedor:Proveedor=new Proveedor('','','','','','','','',false);
  public proveedores:Proveedor[]=[];
  public isLoadingProv:Boolean=false;
  public descProv:string='';
  public faPlusCircle=faPlusCircle;
  faMinusCircle = faMinusCircle;
  public count:number=0;
  public pagNumber:number=0;
  public pageNumberSearch:number=0;
  public numeros:number[]=[];
  public currentPage:number=0;
  public HighlightRow:number=-1;
  public codigoBarras:string='';
  public isAddProveedor:Boolean=false;
  public pageClasses:string[]=[];
  public siguienteClass:string='page-item';
  public anteriorClass:string='page-item disabled';
  public action:string=Global.AGREGAR

  getListaProveedores(page:number):void{
    this.isLoadingProv = true;
    this.proveedorService.getAllProveedores(page)
    .subscribe(res=>{
      this.isLoadingProv = false;
      if(res.status===Global.OK){
        this.proveedores = res.body.listaProveedores.proveedores;
        this.count = res.body.count.count;
        this.pagNumber = Math.ceil(this.count/Global.PAGE_SIZE_PROVEEDOR);
        this.numeros = Array(this.pagNumber).fill(0).map((x,i)=>i);
        this.pageClasses = Array(this.pagNumber).fill('page-link');
        this.pageClasses[page]='page-link disabled';
      }
    })
  }

  busquedaProveeDesc():void{
    this.isLoadingProv = true;
    this.proveedorService.getProveedorByDesc(this.descProv)
    .subscribe(res=>{
      if(res.status===Global.OK){
        this.proveedores = res.body.proveedor;
      }
    });
  }

  selectRow(index:number):void{
    this.HighlightRow=index;
  }

  addProveedor():void{
    this.isAddProveedor = true;
    this.proveedor = new Proveedor('','','','','','','','',true);
  }

  deleteProveedor():void{
    Swal.fire({
      titleText:'Está seguro que desea eliminar éste proveedor?',
      text: 'esto puede generar inconsistencias en el Sistema?',
      icon:'warning',
      showCancelButton:true,
      confirmButtonText:'Si'
    })
    .then(response=>{
      if(response.isConfirmed){
        this.proveedorService.deleteProveedor(this.proveedores[this.HighlightRow]._id)
        .subscribe(res=>{
          if(res.status===Global.OK){
            Swal.fire({
              titleText:'El proveedor ha sido eliminado de la base de datos',
              icon:'success',
              timer:Global.TIMER_OFF
            });
            this.getListaProveedores(Global.PAGE_INIT);
          }
        })
      }
    })
  }

  editaProveedor(index:number):void{
    this.proveedor = this.proveedores[index];
    this.isAddProveedor = true;
    this.action = Global.ACTUALIZAR;
  }

  prev():void{
    this.HighlightRow = -1;
    this.gotoPage(this.currentPage-1);
  }

  gotoPage(page:number):void{
    this.HighlightRow = -1;
    this.currentPage = page;
    this.anteriorClass = 'page-item';
    this.siguienteClass = 'page-item';
    if(this.currentPage===0){
      this.anteriorClass = 'page-item disabled';
    }else if(this.currentPage===this.numeros.length-1){
      this.siguienteClass = 'page-item disabled';
    }
    this.getListaProveedores(page);
  }

  next():void{
    this.HighlightRow = -1;
    this.gotoPage(this.currentPage+1);
  }

  cancelProvAltaUpdt(event:boolean):void{
    this.HighlightRow = -1;
    this.isAddProveedor = false;
    this.proveedor = new Proveedor('','','','','','','','',false);
    if(event){
      this.getListaProveedores(Global.PAGE_INIT);
    }
  }
}
